<template>
  <div class="page">
    <div class="owl-carousel owl-theme">
      <div
        class="item"
        :class="`item-${index + 1}`"
        style="background-image: url('')"
        v-for="(image, index) in imageListNames"
        :key="index"
        :data-responsimgtablet="`/images/Residences/${image}_tablet.jpg`"
        :data-responsimgtv="`/images/Residences/${image}.jpg`"
      ></div>
    </div>

    <div class="thumbnails-slider hidden-tv">
      <div
        v-for="(image, index) in imageListNames"
        :key="index"
        class="thumbnails-slider-item"
        :style="`background-image: url('/images/Residences/${image}_tablet.jpg')`"
        @click.prevent="goToSlide(index, $event)"
      >
        <div
          class="thumbnails-slider-item--overlay"
          :class="index === 0 ? 'active' : ''"
        >
          <div class="thumbnails-slider-item--overlay-content animated faster">
            <div
              class="thumbnails-slider-item--overlay-title"
              v-text="imageListLabels[index]"
            ></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import slidersMixins from "../mixins/sliders";
export default {
  mixins: [slidersMixins],
  data() {
    return {
      imageListNames: [
        "Living",
        "Kitchen",
        "Breakfast-Nook",
        "Primary-Bedroom",
        "Primary-Bath",
        "PH-Living",
        "PH-Roof-Terrace",
        "View",
      ],

      imageListLabels: [
        "Living",
        "Kitchen",
        "Breakfast Nook",
        "Bedroom",
        "Bath",
        "PH Lounge",
        "PH Roof Terrace",
        "View",
      ],
    };
  },
};
</script>
