import Vue from "vue";
import App from "./App.vue";
import router from "./router";

Vue.config.productionTip = false;

import './bootstrap';

import Event from './Event';
window.Event = new Event;

import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel';

new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
