import "./assets/sass/app.scss";

try {
    window.$ = window.jQuery = require('jquery');
} catch (e) {}

import Vue from 'vue';
import VueRouter from 'vue-router';

// Vendors
require('./vendor/jquery.responsImg.min');

window.Vue = Vue;
Vue.use(VueRouter);

window.owlOptions = {
    margin: 0,
    loop: true,
    autoWidth: true,
    autoplay: false,
    mouseDrag: false,
    touchDrag: false,
    pullDrag: false,
    dots: false,
    items: 1,
    animateIn: 'fadeIn',
    animateOut: 'fadeOut'
};

// Responsive images
window.imgOptions = {
    allowDownsize: true,
    elementQuery: true,
    delay: 50,
    breakpoints: {
        tablet: 768,
        tv: 1400
    }
}
