<template>
  <div class="page">
    <div class="owl-carousel owl-theme">
      <div
        class="item"
        :class="`item-${index + 1}`"
        style="background-image: url('')"
        v-for="(image, index) in images"
        :key="index"
        :data-responsimgtablet="`/images/Neighborhood/${buildImageName(index + 1)}_tablet.jpg`"
        :data-responsimgtv="`/images/Neighborhood/${buildImageName(index + 1)}.jpg`"
      ></div>
    </div>

    <div class="thumbnails-slider hidden-tv">
      <div
        v-for="(image, index) in images"
        :key="index"
        class="thumbnails-slider-item"
        :style="`background-image: url('/images/Neighborhood/${buildImageName(index + 1)}_tablet.jpg')`"
        @click.prevent="goToSlide(index, $event)"
      >
        <div
          class="thumbnails-slider-item--overlay"
          :class="index === 0 ? 'active' : ''"
        >
          <div class="thumbnails-slider-item--overlay-content animated faster">
            <div
              class="thumbnails-slider-item--overlay-title"
              v-text="imageListLabels[index]"
            ></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import slidersMixins from "../mixins/sliders";
export default {
  mixins: [slidersMixins],
  data() {
    return {
      imagesCount: 12,

      imageListLabels: [],
    };
  },

  methods: {
    buildImageName(id) {
      return id >= 10 ? `N${id}` : `N0${id}`;
    },
  },

  computed: {
    images() {
      return new Array(this.imagesCount);
    },
  },
};
</script>
