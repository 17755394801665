<template>
  <div :class="classes">
    <div class="sidebar-wrapper">
      <a href="/#/" @click="onLinkClick">
        <img :src="logoPath" alt="ArchiveLofts" class="sidebar-logo" />
      </a>

      <div class="nav">
        <router-link :to="'/'" @click.native="onLinkClick">
          Building
        </router-link>

        <router-link :to="'/residences'" @click.native="onLinkClick">
          Residences
        </router-link>

        <router-link :to="'/neighborhood'" @click.native="onLinkClick">
          Neighborhood
        </router-link>

        <router-link :to="'/map'" @click.native="onLinkClick">
          Map
        </router-link>

        <router-link :to="'/units/available'" @click.native="onLinkClick">
          Available
        </router-link>

        <router-link :to="'/units/1-bedroom'" @click.native="onLinkClick">
          1 Bedrooms
        </router-link>

        <router-link :to="'/units/2-bedroom'" @click.native="onLinkClick">
          2 Bedrooms
        </router-link>

        <router-link
          :to="'/units/3-plus-bedroom'"
          @click.native="onLinkClick"
        >
          3+ Bedrooms
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    onLinkClick: {
      type: Function,
      default: () => {},
    },
  },

  computed: {
    logoPath() {
      return "/images/Menu/logo.svg";
    },

    classes() {
      return `sidebar sidebar-${this.type}`;
    },
  },
};
</script>

<style scoped>
@media screen and (min-width: 1400px) {
  .sidebar .nav {
    display: none;
  }
}
</style>
