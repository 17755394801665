import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    name: "building",
    path: "/",
    component: require("../views/Building.vue").default,
  },
  {
    name: "residences",
    path: "/residences",
    component: require("../views/Residences.vue").default,
  },
  {
    name: "neighborhood",
    path: "/neighborhood",
    component: require("../views/Neighborhood.vue").default,
  },
  {
    name: "map",
    path: "/map",
    component: require("../views/Map.vue").default,
  },
  {
    name: "units",
    path: "/units/:type",
    component: require("../views/Units.vue").default,
  },
];

const router = new VueRouter({
  routes,
});

export default router;
