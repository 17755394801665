<template>
  <div class="page">
    <div class="owl-carousel owl-theme">
      <div
        class="item"
        :class="`item-${index + 1}`"
        style="background-image: url('')"
        v-for="(image, index) in imageListNames"
        :key="index"
        :data-responsimgtablet="`/images/Map/${image}_tablet.png`"
        :data-responsimgtv="`/images/Map/${image}.png`"
      ></div>
    </div>

    <div class="thumbnails-slider hidden-tv">
      <div
        v-for="(image, index) in imageListNames"
        :key="index"
        class="thumbnails-slider-item"
        @click.prevent="goToSlide(index, $event)"
      >
        <div
          class="thumbnails-slider-item--overlay"
          :class="index === 0 ? 'active' : ''"
        >
          <div class="thumbnails-slider-item--overlay-content animated faster">
            <div
              class="thumbnails-slider-item--overlay-title"
              v-text="imageListLabels[index]"
            ></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import slidersMixins from "../mixins/sliders";
export default {
  mixins: [slidersMixins],
  data() {
    return {
      imageListNames: ["Food", "Shops", "Culture", "Wellness"],

      imageListLabels: ["Food", "Shops", "Culture", "Wellness"],
    };
  },
};
</script>

<style scoped>
.thumbnails-slider-item--overlay {
  opacity: 1;
  background: #434343;
}

.thumbnails-slider {
  background: #000;
}

.thumbnails-slider-item--overlay.active {
  background: #000;
}

.thumbnails-slider-item--overlay-title {
  color: #fff;
}
</style>
