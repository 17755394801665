<template>
  <div class="page" :class="type">
    <!-- <div class="table-alert">
      <span> </span>
    </div> -->

    <div class="units-wrapper">
      <div class="units-building">
        <svg id="building" xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 435 608">
    <polygon id="_PH4" class="cls-1"
        points="342.07 166.54 188.42 254.69 188.42 282.94 342.07 194.79 342.07 166.54" />
    <polygon id="_PH3" class="cls-1"
        points="294 265.69 235 232.31 235 262.31 294 295.69 405 232.31 405 202.31 294 265.69" />
    <polygon id="_PH2" class="cls-1"
        points="306.31 281.41 306.31 281.09 179.66 207.97 179.66 236.22 306.25 309.3 306.25 309.7 418.21 245.24 418.21 216.99 306.31 281.41" />
    <polygon id="_PH1" class="cls-1"
        points="418.05 167.56 419 167 225 54 177 82 205 65 150 35 38.47 106.42 36 105 35 124.56 306.04 275.68 306.04 233.98 306.6 233.65 306.97 276.04 419 212.13 418.6 167.25 418.05 167.56" />
    <polygon id="_505" class="cls-1" points="135 351.31 235 407.69 235 377.69 135 321.31 135 351.31" />
    <polygon id="_405" class="cls-1" points="135 380.31 235 436.69 235 406.69 135 350.31 135 380.31" />
    <polygon id="_305" class="cls-1" points="135 409.31 235 465.69 235 435.69 135 379.31 135 409.31" />
    <polygon id="_904" class="cls-1"
        points="294 295.69 187 234.31 187 264.31 294 325.69 349 294.31 349 264.31 294 295.69" />
    <polygon id="_804" class="cls-1"
        points="294 324.69 187 263.31 187 293.31 294 354.69 349 323.31 349 293.31 294 324.69" />
    <polygon id="_704" class="cls-1"
        points="294 352.69 187 291.31 187 321.31 294 382.69 349 351.31 349 321.31 294 352.69" />
    <polygon id="_604" class="cls-1"
        points="294 381.69 187 320.31 187 350.31 294 411.69 349 380.31 349 350.31 294 381.69" />
    <polygon id="_504" class="cls-1"
        points="294 410.69 235 377.31 235 407.31 294 440.69 349 409.31 349 379.31 294 410.69" />
    <polygon id="_404" class="cls-1"
        points="294 439.69 235 406.31 235 436.31 294 469.69 349 438.31 349 408.31 294 439.69" />
    <polygon id="_304" class="cls-1"
        points="294 468.69 235 435.31 235 465.31 294 498.69 349 467.31 349 437.31 294 468.69" />
    <polygon id="_903" class="cls-1"
        points="134 294.41 79 263.03 79 293.03 134 324.41 193 291.03 193 261.03 134 294.41" />
    <polygon id="_803" class="cls-1"
        points="134 323.41 79 292.03 79 322.03 134 353.41 193 320.03 193 290.03 134 323.41" />
    <polygon id="_703" class="cls-1"
        points="134 351.41 79 320.03 79 350.03 134 381.41 193 348.03 193 318.03 134 351.41" />
    <polygon id="_603" class="cls-1"
        points="134 380.41 79 349.03 79 379.03 134 410.41 193 377.03 193 347.03 134 380.41" />
    <polygon id="_503" class="cls-1"
        points="134 408.41 79 377.03 79 407.03 134 438.41 193 405.03 193 375.03 134 408.41" />
    <polygon id="_403" class="cls-1"
        points="134 437.41 79 406.03 79 436.03 134 467.41 193 434.03 193 404.03 134 437.41" />
    <polygon id="_303" class="cls-1"
        points="133 465.41 78 434.03 78 464.03 133 495.41 192 462.03 192 432.03 133 465.41" />
    <polygon id="_902" class="cls-1"
        points="306.31 310.03 306.31 309.71 179.66 236.58 179.66 264.84 306.25 337.92 306.25 338.31 418.21 273.86 418.21 245.61 306.31 310.03" />
    <polygon id="_802" class="cls-1"
        points="306.31 338.64 306.31 338.32 179.66 265.2 179.66 293.45 306.25 366.54 306.25 366.93 418.21 302.47 418.21 274.22 306.31 338.64" />
    <polygon id="_702" class="cls-1"
        points="306.31 367.26 306.31 366.94 179.66 293.82 179.66 322.07 306.25 395.15 306.25 395.54 418.21 331.09 418.21 302.84 306.31 367.26" />
    <polygon id="_602" class="cls-1"
        points="306.31 395.87 306.31 395.55 179.66 322.43 179.66 350.68 306.25 423.77 306.25 424.16 418.21 359.7 418.21 331.45 306.31 395.87" />
    <polygon id="_502" class="cls-1"
        points="306.31 424.49 306.31 424.17 179.66 351.05 179.66 379.3 306.25 452.38 306.25 452.77 363.41 419.87 363.41 391.63 418.21 360.08 418.21 360.07 306.31 424.49" />
    <polygon id="_402" class="cls-1"
        points="306.31 453.1 306.31 452.78 179.66 379.66 179.66 407.91 306.25 481 306.25 481.39 363.41 448.48 363.41 420.24 418.21 388.69 418.21 388.68 306.31 453.1" />
    <polygon id="_302" class="cls-1"
        points="306.31 481.72 306.31 481.4 179.66 408.28 179.66 436.53 306.25 509.61 306.25 510.01 363.41 477.1 363.41 448.86 418.21 417.31 418.21 417.3 306.31 481.72" />
    <polygon id="_901" class="cls-1"
        points="302.04 218.43 188.42 283.54 188.42 311.79 302.04 246.68 302.04 218.43" />
    <polygon id="_801" class="cls-1"
        points="302.04 247.1 188.42 312.21 188.42 340.46 302.04 275.35 302.04 247.1" />
    <polygon id="_701" class="cls-1"
        points="302.04 275.77 188.42 340.88 188.42 369.13 302.04 304.02 302.04 275.77" />
    <polygon id="_601" class="cls-1"
        points="302.04 304.43 188.42 369.54 188.42 397.79 302.04 332.68 302.04 304.43" />
    <polygon id="_501" class="cls-1"
        points="273.39 348.7 146.74 421.82 146.74 422.14 34.84 357.72 34.84 357.73 89.64 389.28 89.64 417.52 146.8 450.43 146.8 450.03 273.39 376.95 273.39 348.7" />
    <polygon id="_401" class="cls-1"
        points="273.39 377.31 146.74 450.44 146.74 450.75 34.84 386.34 34.84 386.35 89.64 417.89 89.64 446.14 146.8 479.04 146.8 478.65 273.39 405.56 273.39 377.31" />
    <polygon id="_301" class="cls-1"
        points="273.39 405.93 146.74 479.05 146.74 479.37 34.84 414.95 34.84 414.96 89.64 446.51 89.64 474.75 146.8 507.66 146.8 507.26 273.39 434.18 273.39 405.93" />
    <g id="Build_1">
        <polyline class="cls-2"
            points="235.42 247.51 227.98 243.22 227.98 256.78 235.42 261.07 235.42 247.51" />
        <polyline class="cls-2"
            points="250.42 256.17 242.98 251.88 242.98 265.44 250.42 269.73 250.42 256.17" />
        <polyline class="cls-2"
            points="272.98 269.2 265.42 264.83 265.42 278.39 272.98 282.76 272.98 269.2" />
        <polyline class="cls-2"
            points="287.98 277.86 280.42 273.49 280.42 287.05 287.98 291.42 287.98 277.86" />
        <line class="cls-2" x1="224.5" y1="186.5" x2="306.56" y2="234.05" />
        <line class="cls-2" x1="224.18" y1="53.92" x2="419.18" y2="166.5" />
        <line class="cls-2" x1="160.24" y1="71.83" x2="272.72" y2="138.04" />
        <line class="cls-2" x1="111.56" y1="124.94" x2="111.56" y2="152.55" />
        <polyline class="cls-2"
            points="276.8 236.31 269.24 231.94 269.24 245.38 276.8 249.75 276.8 236.31" />
        <polyline class="cls-2"
            points="295.16 246.91 287.72 242.61 287.72 256.05 295.16 260.35 295.16 246.91" />
        <line class="cls-2" x1="111.56" y1="132.87" x2="111.56" y2="100.86" />
        <polyline class="cls-2"
            points="239.24 214.62 231.8 210.33 231.8 223.77 239.24 228.06 239.24 214.62" />
        <polyline class="cls-2"
            points="257.6 225.22 250.16 220.93 250.16 234.37 257.6 238.66 257.6 225.22" />
        <polyline class="cls-2"
            points="285.92 241.57 278.48 237.28 278.48 250.72 285.92 255.01 285.92 241.57" />
        <polyline class="cls-2"
            points="248.48 219.96 240.92 215.59 240.92 229.03 248.48 233.4 248.48 219.96" />
        <polyline class="cls-2"
            points="111.56 151.94 35.84 108.23 35.84 441.91 306.56 598.21 306.56 234.05" />
        <polyline class="cls-2" points="111.83 101.01 224.12 165.84 224.12 186.45" />
        <line class="cls-2" x1="307.33" y1="597.81" x2="418.21" y2="533.79" />
        <line class="cls-2" x1="407.65" y1="501.13" x2="407.65" y2="539.29" />
        <polyline class="cls-2"
            points="393.25 500.32 392.65 500.79 391.93 501.32 391.33 501.79 390.73 502.26 390.01 502.91 389.41 503.38 388.81 503.84 388.21 504.43 387.61 504.9 387.01 505.36 386.41 505.95 386.05 506.28 385.81 506.54 385.45 506.86 385.21 507 384.97 507.26 384.61 507.59 384.37 507.85 384.01 508.18 383.77 508.43 383.53 508.69 383.29 508.95 382.93 509.4 382.69 509.66 382.45 509.92 382.21 510.17 381.97 510.43 381.73 510.81 381.49 511.07 381.25 511.45 381.01 511.71 380.89 511.9 380.77 511.97 380.77 512.09 380.65 512.28 380.53 512.34 380.41 512.53 380.41 512.65 380.29 512.84 380.17 513.03 379.93 513.41 379.81 513.6 379.69 513.91 379.57 514.22 379.45 514.53 379.33 514.84 379.21 515.15 379.09 515.46 379.09 515.58 378.97 515.89 378.97 516.01 378.97 516.13 378.85 516.31 378.85 516.43 378.85 516.55 378.85 516.67 378.85 516.79 378.85 516.91 378.85 517.03 378.85 517.15 378.85 517.27 378.85 517.51 378.85 517.63 378.85 517.75" />
        <line class="cls-2" x1="378.85" y1="517.75" x2="378.85" y2="556.51" />
        <polyline class="cls-2"
            points="393.25 500.32 393.85 500.09 394.57 499.8 395.17 499.57 395.77 499.35 396.49 499.17 397.09 498.94 397.69 498.72 398.29 498.61 398.89 498.38 399.49 498.16 400.09 498.05 400.45 497.96 400.69 497.95 401.05 497.86 401.29 497.72 401.65 497.63 401.89 497.61 402.13 497.59 402.49 497.51 402.73 497.49 402.97 497.47 403.21 497.45 403.57 497.48 403.81 497.46 404.05 497.45 404.29 497.43 404.53 497.41 404.77 497.51 405.01 497.49 405.25 497.59 405.49 497.57 405.61 497.62 405.73 497.56 405.85 497.61 405.85 497.73 405.97 497.66 406.09 497.71 406.21 497.76 406.21 497.88 406.45 497.86 406.57 498.03 406.69 498.08 406.81 498.25 406.93 498.42 407.05 498.59 407.17 498.76 407.29 498.93 407.41 499.11 407.53 499.16 407.53 499.4 407.53 499.52 407.65 499.57 407.65 499.69 407.65 499.81 407.65 499.93 407.65 500.05 407.65 500.17 407.65 500.29 407.65 500.41 407.65 500.53 407.65 500.65 407.65 500.89 407.65 501.01 407.65 501.13" />
        <polyline class="cls-2" points="370.69 530.87 354.25 540.36 354.25 570.72" />
        <polyline class="cls-2"
            points="331.93 535.72 331.33 536.19 330.61 536.73 330.01 537.19 329.41 537.66 328.69 538.31 328.09 538.78 327.49 539.25 326.89 539.83 326.29 540.3 325.69 540.77 325.09 541.35 324.73 541.68 324.49 541.94 324.13 542.27 323.89 542.41 323.53 542.73 323.29 542.99 323.05 543.25 322.69 543.58 322.45 543.84 322.21 544.1 321.85 544.42 321.61 544.8 321.37 545.06 321.13 545.32 320.89 545.58 320.65 545.84 320.41 546.21 320.17 546.47 319.93 546.85 319.69 547.11 319.57 547.3 319.45 547.37 319.33 547.56 319.33 547.68 319.21 547.75 319.09 547.94 318.97 548.13 318.97 548.25 318.73 548.5 318.61 548.81 318.49 549 318.37 549.31 318.25 549.62 318.13 549.93 318.01 550.24 317.89 550.55 317.77 550.86 317.65 551.05 317.65 551.29 317.65 551.41 317.53 551.6 317.53 551.72 317.53 551.84 317.53 551.96 317.53 552.08 317.53 552.2 317.53 552.32 317.53 552.44 317.53 552.56 317.53 552.68 317.41 552.99 317.53 553.04 317.53 553.16" />
        <polyline class="cls-2"
            points="331.93 535.72 332.53 535.5 333.25 535.2 333.85 534.98 334.45 534.75 335.17 534.57 335.77 534.35 336.37 534.12 336.97 534.01 337.57 533.79 338.17 533.56 338.77 533.45 339.13 533.37 339.37 533.35 339.73 533.26 339.97 533.12 340.21 533.1 340.57 533.02 340.81 533 341.05 532.98 341.41 532.89 341.65 532.87 341.89 532.85 342.25 532.89 342.49 532.87 342.73 532.85 342.97 532.83 343.21 532.81 343.45 532.91 343.69 532.89 343.93 533 344.17 532.98 344.29 533.03 344.41 532.96 344.41 533.08 344.53 533.13 344.65 533.06 344.77 533.11 344.77 533.23 344.89 533.28 345.01 533.33 345.25 533.43 345.37 533.48 345.49 533.65 345.61 533.83 345.73 534 345.85 534.17 345.97 534.34 346.09 534.51 346.09 534.63 346.21 534.8 346.21 534.92 346.21 535.04 346.33 535.09 346.33 535.21 346.33 535.33 346.33 535.45 346.33 535.57 346.33 535.69 346.33 535.81 346.33 535.93 346.33 536.05 346.33 536.29 346.33 536.41 346.33 536.53" />
        <line class="cls-2" x1="346.33" y1="536.53" x2="346.33" y2="574.69" />
        <line class="cls-2" x1="370.69" y1="530.87" x2="370.69" y2="561.23" />
        <line class="cls-2" x1="317.53" y1="553.16" x2="317.53" y2="591.32" />
        <polyline class="cls-2"
            points="418.21 533.79 418.21 199.64 407.71 175.69 316.93 228.1 306.73 264.23 306.73 598.15" />
        <polyline class="cls-2"
            points="321.25 244.81 337.33 235.53 337.33 255.81 321.25 265.09 321.25 244.81" />
        <path class="cls-2" d="m337.69,234.18c0-4.61-3.73-6.18-8.34-3.52s-8.34,8.55-8.34,13.16" />
        <polyline class="cls-2"
            points="354.49 225.62 370.57 216.33 370.57 236.61 354.49 245.9 354.49 225.62" />
        <path class="cls-2" d="m370.93,214.99c0-4.61-3.73-6.18-8.34-3.52s-8.34,8.55-8.34,13.16" />
        <polyline class="cls-2"
            points="387.73 206.43 403.81 197.14 403.81 217.42 387.73 226.71 387.73 206.43" />
        <path class="cls-2" d="m404.17,195.8c0-4.61-3.73-6.18-8.34-3.52s-8.34,8.55-8.34,13.16" />
        <polyline class="cls-2"
            points="318.4 227.19 318.4 226.77 319.33 223.36 312.61 226.76 305.89 231.12 306.73 233.51 306.73 264.23" />
        <polyline class="cls-2"
            points="406.56 176.29 406.56 175.87 405.63 173.53 412.35 169.17 419.07 165.77 418.23 169.13 418.23 199.86 405.63 207.13" />
        <polyline class="cls-2" points="272.72 157.87 272.72 138.04 224.24 166.03" />
        <line class="cls-2" x1="160.24" y1="71.83" x2="111.76" y2="99.82" />
        <line class="cls-2" x1="150.01" y1="34.67" x2="203.79" y2="64.94" />
        <line class="cls-2" x1="150.01" y1="38.67" x2="200.5" y2="67.5" />
        <line class="cls-2" x1="150.01" y1="34.67" x2="36" y2="107.76" />
        <line class="cls-2" x1="225.02" y1="53.78" x2="176.54" y2="81.77" />
        <line class="cls-2" x1="224.12" y1="186.45" x2="272.72" y2="157.87" />
        <polygon class="cls-2"
            points="340.43 287.63 323.99 297.12 323.99 274.14 340.43 264.65 340.43 287.63" />
        <polygon class="cls-2"
            points="401.19 252.55 384.75 262.04 384.75 239.06 401.19 229.57 401.19 252.55" />
        <polygon class="cls-2"
            points="373.42 264.12 351.76 276.62 351.76 258.15 373.42 245.65 373.42 264.12" />
        <polyline class="cls-2"
            points="235.42 276.13 227.98 271.83 227.98 285.39 235.42 289.69 235.42 276.13" />
        <polyline class="cls-2"
            points="250.42 284.79 242.98 280.49 242.98 294.05 250.42 298.35 250.42 284.79" />
        <polyline class="cls-2"
            points="272.98 297.81 265.42 293.45 265.42 307.01 272.98 311.37 272.98 297.81" />
        <polyline class="cls-2"
            points="287.98 306.47 280.42 302.11 280.42 315.67 287.98 320.03 287.98 306.47" />
        <polygon class="cls-2"
            points="340.43 316.25 323.99 325.74 323.99 302.76 340.43 293.27 340.43 316.25" />
        <polygon class="cls-2"
            points="401.19 281.16 384.75 290.65 384.75 267.67 401.19 258.18 401.19 281.16" />
        <polygon class="cls-2"
            points="373.42 292.73 351.76 305.23 351.76 286.77 373.42 274.27 373.42 292.73" />
        <polyline class="cls-2"
            points="235.42 304.74 227.98 300.45 227.98 314.01 235.42 318.3 235.42 304.74" />
        <polyline class="cls-2"
            points="250.42 313.4 242.98 309.11 242.98 322.67 250.42 326.96 250.42 313.4" />
        <polyline class="cls-2"
            points="272.98 326.43 265.42 322.06 265.42 335.62 272.98 339.99 272.98 326.43" />
        <polyline class="cls-2"
            points="287.98 335.09 280.42 330.72 280.42 344.28 287.98 348.65 287.98 335.09" />
        <polygon class="cls-2"
            points="340.43 344.86 323.99 354.35 323.99 331.37 340.43 321.88 340.43 344.86" />
        <polygon class="cls-2"
            points="401.19 309.78 384.75 319.27 384.75 296.29 401.19 286.8 401.19 309.78" />
        <polygon class="cls-2"
            points="373.42 321.35 351.76 333.85 351.76 315.38 373.42 302.88 373.42 321.35" />
        <polyline class="cls-2"
            points="235.42 333.36 227.98 329.06 227.98 342.62 235.42 346.92 235.42 333.36" />
        <polyline class="cls-2"
            points="250.42 342.02 242.98 337.72 242.98 351.28 250.42 355.58 250.42 342.02" />
        <polyline class="cls-2"
            points="272.98 355.04 265.42 350.68 265.42 364.24 272.98 368.6 272.98 355.04" />
        <polyline class="cls-2"
            points="287.98 363.7 280.42 359.34 280.42 372.9 287.98 377.26 287.98 363.7" />
        <polygon class="cls-2"
            points="340.43 373.48 323.99 382.97 323.99 359.99 340.43 350.5 340.43 373.48" />
        <polygon class="cls-2"
            points="401.19 338.39 384.75 347.89 384.75 324.91 401.19 315.41 401.19 338.39" />
        <polygon class="cls-2" points="373.42 349.96 351.76 362.46 351.76 344 373.42 331.5 373.42 349.96" />
        <polyline class="cls-2"
            points="235.42 361.97 227.98 357.68 227.98 371.24 235.42 375.53 235.42 361.97" />
        <polyline class="cls-2"
            points="250.42 370.63 242.98 366.34 242.98 379.9 250.42 384.19 250.42 370.63" />
        <polyline class="cls-2"
            points="272.98 383.66 265.42 379.29 265.42 392.85 272.98 397.22 272.98 383.66" />
        <polyline class="cls-2"
            points="287.98 392.32 280.42 387.95 280.42 401.51 287.98 405.88 287.98 392.32" />
        <polygon class="cls-2"
            points="340.43 402.09 323.99 411.58 323.99 388.6 340.43 379.11 340.43 402.09" />
        <polygon class="cls-2"
            points="401.19 367.01 384.75 376.5 384.75 353.52 401.19 344.03 401.19 367.01" />
        <polygon class="cls-2"
            points="373.42 378.58 351.76 391.08 351.76 372.61 373.42 360.11 373.42 378.58" />
        <polyline class="cls-2"
            points="235.42 390.59 227.98 386.29 227.98 399.85 235.42 404.15 235.42 390.59" />
        <polyline class="cls-2"
            points="250.42 399.25 242.98 394.95 242.98 408.51 250.42 412.81 250.42 399.25" />
        <polyline class="cls-2"
            points="272.98 412.27 265.42 407.91 265.42 421.47 272.98 425.83 272.98 412.27" />
        <polyline class="cls-2"
            points="287.98 420.93 280.42 416.57 280.42 430.13 287.98 434.49 287.98 420.93" />
        <polygon class="cls-2"
            points="340.43 430.71 323.99 440.2 323.99 417.22 340.43 407.73 340.43 430.71" />
        <polygon class="cls-2"
            points="401.19 395.62 384.75 405.12 384.75 382.14 401.19 372.64 401.19 395.62" />
        <polygon class="cls-2"
            points="373.42 407.19 351.76 419.7 351.76 401.23 373.42 388.73 373.42 407.19" />
        <polyline class="cls-2"
            points="235.42 419.2 227.98 414.91 227.98 428.47 235.42 432.76 235.42 419.2" />
        <polyline class="cls-2"
            points="250.42 427.86 242.98 423.57 242.98 437.13 250.42 441.42 250.42 427.86" />
        <polyline class="cls-2"
            points="272.98 440.89 265.42 436.52 265.42 450.08 272.98 454.45 272.98 440.89" />
        <polyline class="cls-2"
            points="287.98 449.55 280.42 445.18 280.42 458.74 287.98 463.11 287.98 449.55" />
        <polygon class="cls-2"
            points="340.43 459.32 323.99 468.81 323.99 445.83 340.43 436.34 340.43 459.32" />
        <polygon class="cls-2"
            points="401.19 424.24 384.75 433.73 384.75 410.75 401.19 401.26 401.19 424.24" />
        <polygon class="cls-2"
            points="373.42 435.81 351.76 448.31 351.76 429.85 373.42 417.34 373.42 435.81" />
        <polyline class="cls-2"
            points="235.42 447.82 227.98 443.52 227.98 457.08 235.42 461.38 235.42 447.82" />
        <polyline class="cls-2"
            points="250.42 456.48 242.98 452.18 242.98 465.74 250.42 470.04 250.42 456.48" />
        <polyline class="cls-2"
            points="272.98 469.5 265.42 465.14 265.42 478.7 272.98 483.06 272.98 469.5" />
        <polyline class="cls-2"
            points="287.98 478.16 280.42 473.8 280.42 487.36 287.98 491.72 287.98 478.16" />
        <polygon class="cls-2"
            points="340.43 487.94 323.99 497.43 323.99 474.45 340.43 464.96 340.43 487.94" />
        <polygon class="cls-2"
            points="401.19 452.85 384.75 462.35 384.75 439.37 401.19 429.87 401.19 452.85" />
        <polygon class="cls-2"
            points="373.42 464.42 351.76 476.93 351.76 458.46 373.42 445.96 373.42 464.42" />
        <polyline class="cls-2"
            points="235.42 476.43 227.98 472.14 227.98 485.7 235.42 489.99 235.42 476.43" />
        <polyline class="cls-2"
            points="250.42 485.09 242.98 480.8 242.98 494.36 250.42 498.65 250.42 485.09" />
        <polyline class="cls-2"
            points="272.98 498.12 265.42 493.75 265.42 507.31 272.98 511.68 272.98 498.12" />
        <polyline class="cls-2"
            points="287.98 506.78 280.42 502.41 280.42 515.97 287.98 520.34 287.98 506.78" />
        <polygon class="cls-2"
            points="340.43 516.55 323.99 526.04 323.99 503.06 340.43 493.57 340.43 516.55" />
        <polygon class="cls-2"
            points="401.19 481.47 384.75 490.96 384.75 467.98 401.19 458.49 401.19 481.47" />
        <polygon class="cls-2"
            points="373.42 493.04 351.76 505.54 351.76 487.08 373.42 474.57 373.42 493.04" />
        <line class="cls-2" x1="150.35" y1="38.59" x2="44.5" y2="107.5" />
        <line class="cls-2" x1="150.35" y1="51.59" x2="55.5" y2="113.5" />
        <line class="cls-2" x1="44.61" y1="107.48" x2="111.5" y2="145.5" />
        <line class="cls-2" x1="151.01" y1="52.21" x2="189.5" y2="74.5" />
        <line class="cls-2" x1="150.11" y1="52" x2="150.11" y2="39.16" />
    </g>
    <g id="Build_2">
        <line class="cls-2" x1="116.17" y1="119.43" x2="33.87" y2="166.15" />
        <line class="cls-2" x1="228.65" y1="33.39" x2="116.17" y2="99.6" />
        <line class="cls-2" x1="277.13" y1="81.42" x2="277.13" y2="61.53" />
        <polyline class="cls-2" points="277.06 62.57 164.77 127.4 164.77 148.01" />
        <line class="cls-2" x1="145.72" y1="597.46" x2="34.84" y2="533.44" />
        <line class="cls-2" x1="45.4" y1="500.78" x2="45.4" y2="538.94" />
        <polyline class="cls-2"
            points="59.8 499.97 60.4 500.44 61.12 500.98 61.72 501.44 62.32 501.91 63.04 502.56 63.64 503.03 64.24 503.5 64.84 504.08 65.44 504.55 66.04 505.02 66.64 505.6 67 505.93 67.24 506.19 67.6 506.52 67.84 506.66 68.08 506.91 68.44 507.24 68.68 507.5 69.04 507.83 69.28 508.09 69.52 508.35 69.76 508.6 70.12 509.05 70.36 509.31 70.6 509.57 70.84 509.83 71.08 510.09 71.32 510.46 71.56 510.72 71.8 511.1 72.04 511.36 72.16 511.55 72.28 511.62 72.28 511.74 72.4 511.93 72.52 512 72.64 512.19 72.64 512.31 72.76 512.5 72.88 512.69 73.12 513.06 73.24 513.25 73.36 513.56 73.48 513.87 73.6 514.18 73.72 514.49 73.84 514.8 73.96 515.11 73.96 515.23 74.08 515.54 74.08 515.66 74.08 515.78 74.2 515.97 74.2 516.09 74.2 516.21 74.2 516.33 74.2 516.45 74.2 516.57 74.2 516.69 74.2 516.81 74.2 516.93 74.2 517.17 74.2 517.29 74.2 517.41" />
        <line class="cls-2" x1="74.2" y1="517.41" x2="74.2" y2="556.17" />
        <polyline class="cls-2"
            points="59.8 499.97 59.2 499.75 58.48 499.45 57.88 499.22 57.28 499 56.56 498.82 55.96 498.6 55.36 498.37 54.76 498.26 54.16 498.04 53.56 497.81 52.96 497.7 52.6 497.62 52.36 497.6 52 497.51 51.76 497.37 51.4 497.28 51.16 497.27 50.92 497.25 50.56 497.16 50.32 497.14 50.08 497.12 49.84 497.1 49.48 497.14 49.24 497.12 49 497.1 48.76 497.08 48.52 497.06 48.28 497.16 48.04 497.14 47.8 497.25 47.56 497.23 47.44 497.28 47.32 497.21 47.2 497.26 47.2 497.38 47.08 497.31 46.96 497.36 46.84 497.41 46.84 497.53 46.6 497.51 46.48 497.68 46.36 497.73 46.24 497.9 46.12 498.08 46 498.25 45.88 498.42 45.76 498.59 45.64 498.76 45.52 498.81 45.52 499.05 45.52 499.17 45.4 499.22 45.4 499.34 45.4 499.46 45.4 499.58 45.4 499.7 45.4 499.82 45.4 499.94 45.4 500.06 45.4 500.18 45.4 500.3 45.4 500.54 45.4 500.66 45.4 500.78" />
        <polyline class="cls-2" points="82.36 530.52 98.8 540.01 98.8 570.37" />
        <polyline class="cls-2"
            points="121.12 535.38 121.72 535.84 122.44 536.38 123.04 536.85 123.64 537.31 124.36 537.97 124.96 538.43 125.56 538.9 126.16 539.49 126.76 539.95 127.36 540.42 127.96 541.01 128.32 541.33 128.56 541.59 128.92 541.92 129.16 542.06 129.52 542.39 129.76 542.64 130 542.9 130.36 543.23 130.6 543.49 130.84 543.75 131.2 544.08 131.44 544.45 131.68 544.71 131.92 544.97 132.16 545.23 132.4 545.49 132.64 545.87 132.88 546.13 133.12 546.5 133.36 546.76 133.48 546.95 133.6 547.02 133.72 547.21 133.72 547.33 133.84 547.4 133.96 547.59 134.08 547.78 134.08 547.9 134.32 548.16 134.44 548.47 134.56 548.66 134.68 548.97 134.8 549.27 134.92 549.58 135.04 549.89 135.16 550.2 135.28 550.51 135.4 550.7 135.4 550.94 135.4 551.06 135.52 551.25 135.52 551.37 135.52 551.49 135.52 551.61 135.52 551.73 135.52 551.85 135.52 551.97 135.52 552.09 135.52 552.21 135.52 552.33 135.64 552.64 135.52 552.69 135.52 552.81" />
        <polyline class="cls-2"
            points="121.12 535.38 120.52 535.15 119.8 534.85 119.2 534.63 118.6 534.4 117.88 534.23 117.28 534 116.68 533.77 116.08 533.67 115.48 533.44 114.88 533.21 114.28 533.11 113.92 533.02 113.68 533 113.32 532.91 113.08 532.77 112.84 532.76 112.48 532.67 112.24 532.65 112 532.63 111.64 532.54 111.4 532.52 111.16 532.51 110.8 532.54 110.56 532.52 110.32 532.5 110.08 532.48 109.84 532.46 109.6 532.57 109.36 532.55 109.12 532.65 108.88 532.63 108.76 532.68 108.64 532.61 108.64 532.73 108.52 532.78 108.4 532.71 108.28 532.76 108.28 532.88 108.16 532.93 108.04 532.98 107.8 533.09 107.68 533.14 107.56 533.31 107.44 533.48 107.32 533.65 107.2 533.82 107.08 533.99 106.96 534.16 106.96 534.28 106.84 534.45 106.84 534.57 106.84 534.69 106.72 534.74 106.72 534.86 106.72 534.98 106.72 535.1 106.72 535.22 106.72 535.34 106.72 535.46 106.72 535.58 106.72 535.7 106.72 535.94 106.72 536.06 106.72 536.18" />
        <line class="cls-2" x1="106.72" y1="536.18" x2="106.72" y2="574.34" />
        <line class="cls-2" x1="82.36" y1="530.52" x2="82.36" y2="560.88" />
        <line class="cls-2" x1="135.52" y1="552.81" x2="135.52" y2="590.97" />
        <polyline class="cls-2"
            points="34.84 533.44 34.84 199.29 45.34 175.35 136.12 227.76 146.32 263.89 146.32 597.81" />
        <polyline class="cls-2"
            points="131.8 244.46 115.72 235.18 115.72 255.46 131.8 264.74 131.8 244.46" />
        <path class="cls-2" d="m115.36,233.83c0-4.61,3.73-6.18,8.34-3.52s8.34,8.55,8.34,13.16" />
        <polyline class="cls-2" points="98.56 225.27 82.48 215.99 82.48 236.27 98.56 245.55 98.56 225.27" />
        <path class="cls-2" d="m82.12,214.64c0-4.61,3.73-6.18,8.34-3.52,4.61,2.66,8.34,8.55,8.34,13.16" />
        <polyline class="cls-2" points="65.32 206.08 49.24 196.8 49.24 217.08 65.32 226.36 65.32 206.08" />
        <path class="cls-2" d="m48.88,195.45c0-4.61,3.73-6.18,8.34-3.52s8.34,8.55,8.34,13.16" />
        <polyline class="cls-2"
            points="134.65 226.84 134.65 226.42 133.72 223.01 140.44 226.41 147.16 230.77 146.32 233.16 146.32 263.89" />
        <polyline class="cls-2"
            points="46.49 175.94 46.49 175.52 47.42 173.19 40.7 168.83 33.98 165.43 34.82 168.79 34.82 199.51 47.42 206.79" />
        <polyline class="cls-2" points="116.17 119.43 116.17 99.6 164.65 127.59" />
        <line class="cls-2" x1="228.65" y1="33.39" x2="277.13" y2="61.38" />
        <line class="cls-2" x1="303.04" y1="34.32" x2="266.19" y2="55.06" />
        <line class="cls-2" x1="303.04" y1="34.32" x2="417.05" y2="107.41" />
        <line class="cls-2" x1="276.9" y1="81.6" x2="341.5" y2="118.5" />
        <line class="cls-2" x1="164.77" y1="148.01" x2="116.17" y2="119.43" />
        <polygon class="cls-2"
            points="112.62 287.28 129.06 296.77 129.06 273.79 112.62 264.3 112.62 287.28" />
        <polygon class="cls-2" points="51.85 252.2 68.29 261.69 68.29 238.71 51.85 229.22 51.85 252.2" />
        <polygon class="cls-2" points="79.63 263.77 101.28 276.27 101.28 257.81 79.63 245.3 79.63 263.77" />
        <polygon class="cls-2"
            points="112.62 315.9 129.06 325.39 129.06 302.41 112.62 292.92 112.62 315.9" />
        <polygon class="cls-2" points="51.85 280.82 68.29 290.31 68.29 267.33 51.85 257.84 51.85 280.82" />
        <polygon class="cls-2"
            points="79.63 292.38 101.28 304.89 101.28 286.42 79.63 273.92 79.63 292.38" />
        <polygon class="cls-2"
            points="112.62 344.51 129.06 354.01 129.06 331.03 112.62 321.53 112.62 344.51" />
        <polygon class="cls-2" points="51.85 309.43 68.29 318.92 68.29 295.94 51.85 286.45 51.85 309.43" />
        <polygon class="cls-2" points="79.63 321 101.28 333.5 101.28 315.04 79.63 302.53 79.63 321" />
        <polygon class="cls-2"
            points="112.62 373.13 129.06 382.62 129.06 359.64 112.62 350.15 112.62 373.13" />
        <polygon class="cls-2" points="51.85 338.05 68.29 347.54 68.29 324.56 51.85 315.07 51.85 338.05" />
        <polygon class="cls-2"
            points="79.63 349.62 101.28 362.12 101.28 343.65 79.63 331.15 79.63 349.62" />
        <polygon class="cls-2"
            points="112.62 401.74 129.06 411.24 129.06 388.26 112.62 378.76 112.62 401.74" />
        <polygon class="cls-2" points="51.85 366.66 68.29 376.15 68.29 353.17 51.85 343.68 51.85 366.66" />
        <polygon class="cls-2"
            points="79.63 378.23 101.28 390.73 101.28 372.27 79.63 359.77 79.63 378.23" />
        <polygon class="cls-2"
            points="112.62 430.36 129.06 439.85 129.06 416.87 112.62 407.38 112.62 430.36" />
        <polygon class="cls-2" points="51.85 395.28 68.29 404.77 68.29 381.79 51.85 372.3 51.85 395.28" />
        <polygon class="cls-2"
            points="79.63 406.85 101.28 419.35 101.28 400.88 79.63 388.38 79.63 406.85" />
        <polygon class="cls-2"
            points="112.62 458.97 129.06 468.47 129.06 445.49 112.62 435.99 112.62 458.97" />
        <polygon class="cls-2" points="51.85 423.89 68.29 433.38 68.29 410.4 51.85 400.91 51.85 423.89" />
        <polygon class="cls-2" points="79.63 435.46 101.28 447.96 101.28 429.5 79.63 417 79.63 435.46" />
        <polygon class="cls-2"
            points="112.62 487.59 129.06 497.08 129.06 474.1 112.62 464.61 112.62 487.59" />
        <polygon class="cls-2" points="51.85 452.51 68.29 462 68.29 439.02 51.85 429.53 51.85 452.51" />
        <polygon class="cls-2"
            points="79.63 464.08 101.28 476.58 101.28 458.11 79.63 445.61 79.63 464.08" />
        <polygon class="cls-2"
            points="112.62 516.21 129.06 525.7 129.06 502.72 112.62 493.23 112.62 516.21" />
        <polygon class="cls-2" points="51.85 481.12 68.29 490.61 68.29 467.63 51.85 458.14 51.85 481.12" />
        <polygon class="cls-2"
            points="79.63 492.69 101.28 505.19 101.28 486.73 79.63 474.23 79.63 492.69" />
        <line class="cls-2" x1="277.13" y1="81.42" x2="164.77" y2="148.01" />
        <line class="cls-2" x1="147.1" y1="597.22" x2="429.82" y2="433.99" />
        <polyline class="cls-2"
            points="164.34 243.66 180.42 234.38 180.42 254.66 164.34 263.94 164.34 243.66" />
        <path class="cls-2" d="m180.78,233.03c0-4.61-3.73-6.18-8.34-3.52s-8.34,8.55-8.34,13.16" />
        <polyline class="cls-2"
            points="195.3 225.79 211.38 216.5 211.38 236.78 195.3 246.07 195.3 225.79" />
        <path class="cls-2" d="m211.74,215.15c0-4.61-3.73-6.18-8.34-3.52s-8.34,8.55-8.34,13.16" />
        <polyline class="cls-2"
            points="246.72 196.1 262.8 186.81 262.8 207.09 246.72 216.38 246.72 196.1" />
        <path class="cls-2" d="m263.16,185.47c0-4.61-3.73-6.18-8.34-3.52s-8.34,8.55-8.34,13.16" />
        <polyline class="cls-2"
            points="313.26 157.68 329.34 148.4 329.34 168.68 313.26 177.96 313.26 157.68" />
        <path class="cls-2" d="m329.7,147.05c0-4.61-3.73-6.18-8.34-3.52-4.61,2.66-8.34,8.55-8.34,13.16" />
        <polyline class="cls-2"
            points="399.9 404.42 406.62 400.54 406.62 386.98 399.9 390.86 399.9 404.42" />
        <polyline class="cls-2"
            points="384.9 413.08 391.62 409.2 391.62 395.64 384.9 399.52 384.9 413.08" />
        <polyline class="cls-2"
            points="362.46 426.04 369.18 422.16 369.18 408.6 362.46 412.48 362.46 426.04" />
        <polyline class="cls-2"
            points="347.46 434.7 354.18 430.82 354.18 417.26 347.46 421.14 347.46 434.7" />
        <polyline class="cls-2"
            points="324.9 447.72 331.62 443.84 331.62 430.28 324.9 434.16 324.9 447.72" />
        <polyline class="cls-2"
            points="166.98 416.74 159.54 421.03 159.54 436.15 166.98 431.86 166.98 416.74" />
        <polyline class="cls-2" points="161.58 414.09 161.58 389.85 179.94 379.25 179.94 403.49" />
        <line class="cls-2" x1="161.22" y1="414.3" x2="180.3" y2="403.29" />
        <polyline class="cls-2" points="161.58 385.53 161.58 361.29 179.94 350.69 179.94 374.93" />
        <line class="cls-2" x1="161.22" y1="385.74" x2="180.3" y2="374.73" />
        <polyline class="cls-2" points="161.58 357.09 161.58 332.73 179.94 322.13 179.94 346.49" />
        <line class="cls-2" x1="161.22" y1="357.3" x2="180.3" y2="346.29" />
        <polyline class="cls-2" points="161.22 298.74 161.22 274.14 180.3 263.13 180.3 287.73" />
        <line class="cls-2" x1="161.22" y1="298.74" x2="180.3" y2="287.73" />
        <polyline class="cls-2" points="199.14 392.41 199.14 368.17 217.38 357.64 217.38 381.88" />
        <line class="cls-2" x1="198.78" y1="392.62" x2="217.74" y2="381.67" />
        <polyline class="cls-2" points="236.58 370.79 236.58 346.55 254.94 335.95 254.94 360.19" />
        <line class="cls-2" x1="236.22" y1="371" x2="255.3" y2="359.98" />
        <polyline class="cls-2" points="199.14 363.85 199.14 339.61 217.38 329.08 217.38 353.32" />
        <line class="cls-2" x1="198.78" y1="364.06" x2="217.74" y2="353.11" />
        <polyline class="cls-2" points="236.58 342.23 236.58 317.99 254.94 307.39 254.94 331.63" />
        <line class="cls-2" x1="236.22" y1="342.44" x2="255.3" y2="331.42" />
        <polyline class="cls-2" points="199.14 335.41 199.14 311.05 217.38 300.52 217.38 324.88" />
        <line class="cls-2" x1="198.78" y1="335.62" x2="217.74" y2="324.67" />
        <polyline class="cls-2" points="236.58 313.79 236.58 289.43 254.94 278.83 254.94 303.19" />
        <line class="cls-2" x1="236.22" y1="314" x2="255.3" y2="302.98" />
        <polyline class="cls-2" points="199.14 276.85 199.14 252.61 217.38 242.08 217.38 266.32" />
        <line class="cls-2" x1="198.78" y1="277.06" x2="217.74" y2="266.11" />
        <polyline class="cls-2" points="236.22 255.44 236.22 230.84 255.3 219.82 255.3 244.42" />
        <line class="cls-2" x1="236.22" y1="255.44" x2="255.3" y2="244.42" />
        <polyline class="cls-2"
            points="416.94 152.66 425.94 147.47 425.94 145.91 416.94 151.1 416.94 152.66" />
        <polyline class="cls-2"
            points="416.94 182.66 425.94 177.47 425.94 175.91 416.94 181.1 425.94 175.91 425.94 168.23 416.94 173.42 425.94 168.23" />
        <polyline class="cls-2"
            points="416.94 211.1 425.94 205.91 425.94 204.47 416.94 209.66 425.94 204.47 425.94 196.79 416.94 201.98 425.94 196.79" />
        <polyline class="cls-2"
            points="416.94 239.66 425.94 234.47 425.94 232.91 416.94 238.1 425.94 232.91 425.94 225.23 416.94 230.42 425.94 225.23" />
        <polyline class="cls-2"
            points="416.94 268.22 425.94 263.03 425.94 261.47 416.94 266.66 425.94 261.47 425.94 253.79 416.94 258.98 425.94 253.79" />
        <polyline class="cls-2"
            points="416.94 294.74 425.94 289.55 425.94 288.11 416.94 293.3 425.94 288.11 425.94 280.43 416.94 285.62 425.94 280.43" />
        <polyline class="cls-2"
            points="416.94 320.3 425.94 315.11 425.94 313.67 416.94 318.86 425.94 313.67 425.94 305.87 416.94 311.06 425.94 305.87" />
        <polyline class="cls-2"
            points="416.94 336.62 425.94 331.43 425.94 339.11 416.94 344.3 425.94 339.11 425.94 340.67 416.94 345.86" />
        <polyline class="cls-2"
            points="416.94 151.1 425.94 145.91 425.94 138.23 416.94 143.42 425.94 138.23" />
        <polyline class="cls-2"
            points="181.98 408.08 174.54 412.37 174.54 427.49 181.98 423.2 181.98 408.08" />
        <polyline class="cls-2"
            points="204.54 395.05 196.98 399.42 196.98 414.54 204.54 410.17 204.54 395.05" />
        <polyline class="cls-2"
            points="219.54 386.39 211.98 390.76 211.98 405.88 219.54 401.51 219.54 386.39" />
        <polyline class="cls-2"
            points="241.98 373.44 234.54 377.73 234.54 392.85 241.98 388.56 241.98 373.44" />
        <polyline class="cls-2"
            points="369.54 326.67 361.98 331.03 361.98 346.15 369.54 341.79 369.54 326.67" />
        <polyline class="cls-2"
            points="354.54 335.33 346.98 339.69 346.98 354.81 354.54 350.45 354.54 335.33" />
        <polyline class="cls-2"
            points="369.54 352.35 361.98 356.71 361.98 371.83 369.54 367.47 369.54 352.35" />
        <polyline class="cls-2"
            points="354.54 361.01 346.98 365.37 346.98 380.49 354.54 376.13 354.54 361.01" />
        <polyline class="cls-2"
            points="369.54 377.67 361.98 382.03 361.98 397.15 369.54 392.79 369.54 377.67" />
        <polyline class="cls-2"
            points="354.54 386.33 346.98 390.69 346.98 405.81 354.54 401.45 354.54 386.33" />
        <polyline class="cls-2"
            points="406.98 356.05 399.54 360.35 399.54 375.47 406.98 371.17 406.98 356.05" />
        <polyline class="cls-2"
            points="391.98 364.71 384.54 369.01 384.54 384.13 391.98 379.83 391.98 364.71" />
        <polyline class="cls-2"
            points="331.98 399.35 324.54 403.65 324.54 418.77 331.98 414.47 331.98 399.35" />
        <polyline class="cls-2"
            points="406.98 330.73 399.54 335.03 399.54 350.15 406.98 345.85 406.98 330.73" />
        <polyline class="cls-2"
            points="391.98 339.39 384.54 343.69 384.54 358.81 391.98 354.51 391.98 339.39" />
        <polyline class="cls-2"
            points="406.98 305.05 399.54 309.35 399.54 324.47 406.98 320.17 406.98 305.05" />
        <polyline class="cls-2"
            points="391.98 313.71 384.54 318.01 384.54 333.13 391.98 328.83 391.98 313.71" />
        <polyline class="cls-2"
            points="406.98 279.49 399.54 283.79 399.54 298.91 406.98 294.61 406.98 279.49" />
        <polyline class="cls-2"
            points="391.98 288.15 384.54 292.45 384.54 307.57 391.98 303.27 391.98 288.15" />
        <polyline class="cls-2"
            points="406.98 252.85 399.54 257.15 399.54 272.27 406.98 267.97 406.98 252.85" />
        <polyline class="cls-2"
            points="391.98 261.51 384.54 265.81 384.54 280.93 391.98 276.63 391.98 261.51" />
        <polyline class="cls-2"
            points="406.98 224.41 399.54 228.71 399.54 243.83 406.98 239.53 406.98 224.41" />
        <polyline class="cls-2"
            points="391.98 233.07 384.54 237.37 384.54 252.49 391.98 248.19 391.98 233.07" />
        <polyline class="cls-2"
            points="406.98 195.85 399.54 200.15 399.54 215.27 406.98 210.97 406.98 195.85" />
        <polyline class="cls-2"
            points="391.98 204.51 384.54 208.81 384.54 223.93 391.98 219.63 391.98 204.51" />
        <polyline class="cls-2"
            points="406.98 167.41 399.54 171.71 399.54 186.83 406.98 182.53 406.98 167.41" />
        <polyline class="cls-2"
            points="391.98 176.07 384.54 180.37 384.54 195.49 391.98 191.19 391.98 176.07" />
        <polyline class="cls-2"
            points="406.98 137.41 399.54 141.71 399.54 156.83 406.98 152.53 406.98 137.41" />
        <polyline class="cls-2"
            points="391.98 146.07 384.54 150.37 384.54 165.49 391.98 161.19 391.98 146.07" />
        <polyline class="cls-2"
            points="369.54 301.11 361.98 305.47 361.98 320.59 369.54 316.23 369.54 301.11" />
        <polyline class="cls-2"
            points="354.54 309.77 346.98 314.13 346.98 329.25 354.54 324.89 354.54 309.77" />
        <polyline class="cls-2"
            points="354.54 283.13 346.98 287.49 346.98 302.61 354.54 298.25 354.54 283.13" />
        <polyline class="cls-2"
            points="354.54 254.69 346.98 259.05 346.98 274.17 354.54 269.81 354.54 254.69" />
        <polyline class="cls-2"
            points="354.54 226.13 346.98 230.49 346.98 245.61 354.54 241.25 354.54 226.13" />
        <polyline class="cls-2"
            points="354.54 197.69 346.98 202.05 346.98 217.17 354.54 212.81 354.54 197.69" />
        <polyline class="cls-2"
            points="369.54 159.03 361.98 163.39 361.98 178.51 369.54 174.15 369.54 159.03" />
        <polyline class="cls-2"
            points="354.54 167.69 346.98 172.05 346.98 187.17 354.54 182.81 354.54 167.69" />
        <polyline class="cls-2" points="161.58 328.53 161.58 303.09 179.94 292.49 179.94 317.93" />
        <line class="cls-2" x1="161.22" y1="328.74" x2="180.3" y2="317.73" />
        <polyline class="cls-2" points="199.14 306.85 199.14 281.41 217.38 270.88 217.38 296.32" />
        <line class="cls-2" x1="198.78" y1="307.06" x2="217.74" y2="296.11" />
        <polyline class="cls-2" points="236.58 285.23 236.58 259.79 254.94 249.19 254.94 274.63" />
        <line class="cls-2" x1="236.22" y1="285.44" x2="255.3" y2="274.42" />
        <polyline class="cls-2" points="314.82 240.06 314.82 214.62 333.06 204.09 333.06 229.53" />
        <line class="cls-2" x1="314.46" y1="240.27" x2="333.42" y2="229.32" />
        <polyline class="cls-2" points="314.82 325.62 314.82 301.38 333.06 290.85 333.06 315.09" />
        <line class="cls-2" x1="314.46" y1="325.83" x2="333.42" y2="314.88" />
        <polyline class="cls-2" points="314.82 297.06 314.82 272.82 333.06 262.29 333.06 286.53" />
        <line class="cls-2" x1="314.46" y1="297.27" x2="333.42" y2="286.32" />
        <polyline class="cls-2" points="314.82 268.62 314.82 244.26 333.06 233.73 333.06 258.09" />
        <line class="cls-2" x1="314.46" y1="268.83" x2="333.42" y2="257.88" />
        <polyline class="cls-2" points="314.82 210.06 314.82 185.82 333.06 175.29 333.06 199.53" />
        <line class="cls-2" x1="314.46" y1="210.27" x2="333.42" y2="199.32" />
        <polyline class="cls-2"
            points="346.98 259.05 354.54 254.69 354.54 269.81 346.98 274.17 346.98 259.05" />
        <polyline class="cls-2"
            points="384.54 237.37 391.98 233.07 391.98 248.19 384.54 252.49 384.54 237.37" />
        <polyline class="cls-2"
            points="399.54 228.71 406.98 224.41 406.98 239.53 399.54 243.83 399.54 228.71" />
        <polyline class="cls-2"
            points="331.98 322.79 324.54 327.09 324.54 342.21 331.98 337.91 331.98 322.79" />
        <polyline class="cls-2"
            points="316.98 331.45 309.54 335.75 309.54 350.87 316.98 346.57 316.98 331.45" />
        <polyline class="cls-2"
            points="294.54 344.41 286.98 348.77 286.98 363.89 294.54 359.53 294.54 344.41" />
        <polyline class="cls-2"
            points="331.98 374.03 324.54 378.33 324.54 393.45 331.98 389.15 331.98 374.03" />
        <polyline class="cls-2"
            points="331.98 348.35 324.54 352.65 324.54 367.77 331.98 363.47 331.98 348.35" />
        <polyline class="cls-2"
            points="161.85 225.83 161.85 225.41 162.78 222 156.06 225.4 149.34 229.76 150.18 232.15 150.18 262.88 161.44 256.38" />
        <polyline class="cls-2"
            points="331.23 128.04 331.23 127.62 330.3 125.28 337.02 120.92 343.74 117.52 342.9 120.88 342.9 151.61" />
        <polyline class="cls-2"
            points="342.5 151.5 416.94 109.1 416.94 369.91 428.58 363.14 428.58 433.99" />
        <line class="cls-2" x1="332.45" y1="127.4" x2="342.78" y2="152.04" />
        <polyline class="cls-2" points="160.7 226.56 150.06 263.31 146.34 265.47 146.34 579.01" />
        <polygon class="cls-2"
            points="222.66 223.55 215.07 227.93 215.07 214.37 222.66 209.99 222.66 223.55" />
        <polygon class="cls-2"
            points="283.22 188.59 275.63 192.97 275.63 179.41 283.22 175.03 283.22 188.59" />
        <polygon class="cls-2"
            points="292.22 183.39 284.63 187.77 284.63 174.21 292.22 169.83 292.22 183.39" />
        <polygon class="cls-2"
            points="237.66 221.61 229.34 226.42 229.34 205.75 237.66 200.94 237.66 221.61" />
        <polygon class="cls-2"
            points="279.54 230.55 271.98 234.91 271.98 215.35 279.54 210.99 279.54 230.55" />
        <polygon class="cls-2"
            points="294.54 221.89 286.98 226.25 286.98 206.69 294.54 202.33 294.54 221.89" />
        <polygon class="cls-2"
            points="279.54 259.73 271.98 264.09 271.98 244.53 279.54 240.17 279.54 259.73" />
        <polygon class="cls-2"
            points="294.54 251.07 286.98 255.43 286.98 235.87 294.54 231.51 294.54 251.07" />
        <polygon class="cls-2"
            points="294.54 278.89 286.98 283.25 286.98 263.69 294.54 259.33 294.54 278.89" />
        <polygon class="cls-2"
            points="294.54 308.07 286.98 312.43 286.98 292.87 294.54 288.51 294.54 308.07" />
        <polygon class="cls-2"
            points="294.54 337.21 286.98 341.57 286.98 322.01 294.54 317.65 294.54 337.21" />
        <line class="cls-2" x1="332.45" y1="127.4" x2="160.7" y2="226.56" />
        <line class="cls-2" x1="301.9" y1="41.21" x2="405.5" y2="107.5" />
        <line class="cls-2" x1="301.9" y1="53.21" x2="396.5" y2="113.5" />
        <line class="cls-2" x1="271.5" y1="57.5" x2="301.47" y2="41.09" />
        <line class="cls-2" x1="271.5" y1="57.5" x2="301.47" y2="41.09" />
        <line class="cls-2" x1="277.5" y1="66.5" x2="301.47" y2="53.09" />
        <line class="cls-2" x1="343.5" y1="142.5" x2="405.94" y2="108.1" />
        <line class="cls-2" x1="301.87" y1="53.12" x2="301.87" y2="41" />
    </g>
    <g id="Build_3">
        <polyline class="cls-2" points="306.75 286.35 306.75 261.75 325.84 250.74 325.84 275.34" />
        <line class="cls-2" x1="306.75" y1="286.35" x2="325.84" y2="275.34" />
        <polyline class="cls-2" points="306.75 316.35 306.75 291.75 325.84 280.74 325.84 305.34" />
        <line class="cls-2" x1="306.75" y1="316.35" x2="325.84" y2="305.34" />
        <polyline class="cls-2" points="306.75 344.91 306.75 320.19 325.84 309.18 325.84 333.9" />
        <line class="cls-2" x1="306.75" y1="344.91" x2="325.84" y2="333.9" />
        <polyline class="cls-2" points="306.75 373.35 306.75 348.75 325.84 337.74 325.84 362.34" />
        <line class="cls-2" x1="306.75" y1="373.35" x2="325.84" y2="362.34" />
        <polyline class="cls-2" points="306.75 401.91 306.75 377.19 325.84 366.18 325.84 390.9" />
        <line class="cls-2" x1="306.75" y1="401.91" x2="325.84" y2="390.9" />
        <polyline class="cls-2" points="306.75 430.55 306.75 405.83 325.84 394.82 325.84 419.54" />
        <line class="cls-2" x1="306.75" y1="430.55" x2="325.84" y2="419.54" />
        <polyline class="cls-2" points="306.75 459.11 306.75 434.39 325.84 423.38 325.84 448.1" />
        <line class="cls-2" x1="306.75" y1="459.11" x2="325.84" y2="448.1" />
        <polyline class="cls-2" points="306.75 487.55 306.75 462.95 325.84 451.94 325.84 476.54" />
        <line class="cls-2" x1="306.75" y1="487.55" x2="325.84" y2="476.54" />
        <polyline class="cls-2" points="372.99 248.11 372.99 223.51 392.08 212.49 392.08 237.09" />
        <line class="cls-2" x1="372.99" y1="248.11" x2="392.08" y2="237.09" />
        <polyline class="cls-2" points="372.99 278.11 372.99 253.51 392.08 242.49 392.08 267.09" />
        <line class="cls-2" x1="372.99" y1="278.11" x2="392.08" y2="267.09" />
        <polyline class="cls-2" points="372.99 306.67 372.99 281.95 392.08 270.93 392.08 295.65" />
        <line class="cls-2" x1="372.99" y1="306.67" x2="392.08" y2="295.65" />
        <polyline class="cls-2" points="372.99 335.11 372.99 310.51 392.08 299.49 392.08 324.09" />
        <line class="cls-2" x1="372.99" y1="335.11" x2="392.08" y2="324.09" />
        <polyline class="cls-2" points="372.99 363.67 372.99 338.95 392.08 327.93 392.08 352.65" />
        <line class="cls-2" x1="372.99" y1="363.67" x2="392.08" y2="352.65" />
        <polyline class="cls-2" points="372.99 392.31 372.99 367.59 392.08 356.57 392.08 381.29" />
        <line class="cls-2" x1="372.99" y1="392.31" x2="392.08" y2="381.29" />
        <polyline class="cls-2" points="372.99 420.87 372.99 396.15 392.08 385.13 392.08 409.85" />
        <line class="cls-2" x1="372.99" y1="420.87" x2="392.08" y2="409.85" />
        <polyline class="cls-2" points="372.99 449.31 372.99 424.71 392.08 413.69 392.08 438.29" />
        <line class="cls-2" x1="372.99" y1="449.31" x2="392.08" y2="438.29" />
        <polyline class="cls-2" points="285.27 180.32 285.27 159.56 291.99 155.68 291.99 176.44" />
        <polyline class="cls-2"
            points="393.75 445.32 386.31 449.62 386.31 463.18 393.75 458.88 393.75 445.32" />
        <polyline class="cls-2"
            points="378.75 453.98 371.31 458.28 371.31 471.84 378.75 467.54 378.75 453.98" />
        <polyline class="cls-2"
            points="360.64 464.45 353.08 468.81 353.08 482.37 360.64 478.01 360.64 464.45" />
        <polyline class="cls-2"
            points="345.75 473.04 338.31 477.33 338.31 490.89 345.75 486.6 345.75 473.04" />
        <polyline class="cls-2"
            points="327.52 483.57 320.08 487.86 320.08 501.42 327.52 497.13 327.52 483.57" />
        <polyline class="cls-2"
            points="312.52 492.23 305.08 496.52 305.08 510.08 312.52 505.79 312.52 492.23" />
        <polyline class="cls-2"
            points="360.64 435.69 353.08 440.05 353.08 453.49 360.64 449.13 360.64 435.69" />
        <polyline class="cls-2"
            points="345.75 444.28 338.31 448.57 338.31 462.01 345.75 457.72 345.75 444.28" />
        <polyline class="cls-2"
            points="360.64 406.13 353.08 410.49 353.08 424.05 360.64 419.69 360.64 406.13" />
        <polyline class="cls-2"
            points="345.75 414.72 338.31 419.01 338.31 432.57 345.75 428.28 345.75 414.72" />
        <polyline class="cls-2"
            points="360.64 376.57 353.08 380.93 353.08 394.49 360.64 390.13 360.64 376.57" />
        <polyline class="cls-2"
            points="345.75 385.16 338.31 389.45 338.31 403.01 345.75 398.72 345.75 385.16" />
        <polyline class="cls-2"
            points="360.64 350.05 353.08 354.41 353.08 367.85 360.64 363.49 360.64 350.05" />
        <polyline class="cls-2"
            points="345.75 358.64 338.31 362.93 338.31 376.37 345.75 372.08 345.75 358.64" />
        <polyline class="cls-2"
            points="360.64 321.49 353.08 325.85 353.08 339.29 360.64 334.93 360.64 321.49" />
        <polyline class="cls-2"
            points="345.75 330.08 338.31 334.37 338.31 347.81 345.75 343.52 345.75 330.08" />
        <polyline class="cls-2"
            points="360.64 292.93 353.08 297.29 353.08 310.85 360.64 306.49 360.64 292.93" />
        <polyline class="cls-2"
            points="345.75 301.52 338.31 305.81 338.31 319.37 345.75 315.08 345.75 301.52" />
        <polyline class="cls-2"
            points="360.64 264.49 353.08 268.85 353.08 282.29 360.64 277.93 360.64 264.49" />
        <polyline class="cls-2"
            points="345.75 273.08 338.31 277.37 338.31 290.81 345.75 286.52 345.75 273.08" />
        <polyline class="cls-2"
            points="360.64 234.49 353.08 238.85 353.08 252.29 360.64 247.93 360.64 234.49" />
        <polyline class="cls-2"
            points="345.75 243.08 338.31 247.37 338.31 260.81 345.75 256.52 345.75 243.08" />
        <polyline class="cls-2"
            points="360.64 494.45 353.08 498.81 353.08 512.37 360.64 508.01 360.64 494.45" />
        <polyline class="cls-2" points="262.23 193.62 262.23 170.94 280.35 160.48 280.35 183.16" />
        <polyline class="cls-2" points="233.47 210.8 233.47 187.12 251.47 176.73 251.47 199.41" />
        <line class="cls-2" x1="405.4" y1="487.8" x2="405.4" y2="187.4" />
        <line class="cls-2" x1="305.85" y1="596.79" x2="417.33" y2="532.43" />
        <polyline class="cls-2" points="405.4 187.4 293.92 251.77 293.92 519.77" />
        <line class="cls-2" x1="333.28" y1="497.04" x2="293.92" y2="519.77" />
        <line class="cls-2" x1="405.4" y1="487.8" x2="345.75" y2="522.24" />
        <polyline class="cls-2" points="417.4 492.8 345.28 534.44 345.28 502.04 305.92 525.77" />
        <line class="cls-2" x1="282.72" y1="148.74" x2="221.14" y2="183.86" />
        <line class="cls-2" x1="218.84" y1="34.12" x2="331.32" y2="100.33" />
        <polyline class="cls-2" points="170.43 63.3 282.72 128.14 282.72 148.74" />
        <polyline class="cls-2" points="331.32 144.29 331.32 100.33 282.84 128.32" />
        <line class="cls-2" x1="170.36" y1="82.16" x2="282.72" y2="148.74" />
        <line class="cls-2" x1="345.28" y1="502.04" x2="333.28" y2="497.04" />
        <line class="cls-2" x1="417.4" y1="493.39" x2="405.4" y2="488.39" />
        <line class="cls-2" x1="417.31" y1="532.43" x2="417.4" y2="492.72" />
        <line class="cls-2" x1="327.39" y1="156.68" x2="233.03" y2="210.48" />
        <line class="cls-2" x1="218.2" y1="34.69" x2="169.72" y2="62.68" />
        <line class="cls-2" x1="169.43" y1="63.3" x2="169.43" y2="81.91" />
        <line class="cls-2" x1="137.26" y1="5.93" x2="25.78" y2="70.29" />
        <line class="cls-2" x1="327.39" y1="146.58" x2="327.39" y2="156.68" />
        <line class="cls-2" x1="327.39" y1="146.58" x2="331.32" y2="144.29" />
        <line class="cls-2" x1="24.08" y1="435.49" x2="306.8" y2="598.72" />
        <polyline class="cls-2" points="41.32 101.84 57.4 111.12 57.4 131.4 41.32 122.12 41.32 101.84" />
        <path class="cls-2" d="m57.76,110.19c0-4.61-3.73-10.5-8.34-13.16s-8.34-1.08-8.34,3.52" />
        <polyline class="cls-2" points="72.28 119.72 88.36 129 88.36 149.28 72.28 140 72.28 119.72" />
        <path class="cls-2" d="m88.72,128.07c0-4.61-3.73-10.5-8.34-13.16s-8.34-1.08-8.34,3.52" />
        <polyline class="cls-2" points="123.7 149.4 139.78 158.69 139.78 178.97 123.7 169.68 123.7 149.4" />
        <path class="cls-2" d="m140.14,157.75c0-4.61-3.73-10.5-8.34-13.16s-8.34-1.08-8.34,3.52" />
        <polyline class="cls-2"
            points="192.24 186.82 208.32 196.1 208.32 216.38 192.24 207.1 192.24 186.82" />
        <path class="cls-2" d="m208.68,195.17c0-4.61-3.73-10.5-8.34-13.16-4.61-2.66-8.34-1.08-8.34,3.52" />
        <polyline class="cls-2"
            points="276.88 534.6 283.6 538.48 283.6 524.92 276.88 521.04 276.88 534.6" />
        <polyline class="cls-2"
            points="261.88 525.94 268.6 529.82 268.6 516.26 261.88 512.38 261.88 525.94" />
        <polyline class="cls-2"
            points="239.44 512.99 246.16 516.87 246.16 503.31 239.44 499.43 239.44 512.99" />
        <polyline class="cls-2"
            points="224.44 504.33 231.16 508.2 231.16 494.64 224.44 490.77 224.44 504.33" />
        <polyline class="cls-2"
            points="201.88 491.3 208.6 495.18 208.6 481.62 201.88 477.74 201.88 491.3" />
        <polyline class="cls-2" points="43.96 277.96 36.52 273.67 36.52 288.79 43.96 293.08 43.96 277.96" />
        <polyline class="cls-2" points="38.56 269.09 38.56 244.85 56.92 255.45 56.92 279.69" />
        <line class="cls-2" x1="38.2" y1="268.88" x2="57.28" y2="279.9" />
        <polyline class="cls-2" points="38.56 240.53 38.56 216.29 56.92 226.89 56.92 251.13" />
        <line class="cls-2" x1="38.2" y1="240.32" x2="57.28" y2="251.34" />
        <polyline class="cls-2" points="38.56 212.09 38.56 187.73 56.92 198.33 56.92 222.69" />
        <line class="cls-2" x1="38.2" y1="211.88" x2="57.28" y2="222.9" />
        <polyline class="cls-2" points="38.2 153.32 38.2 128.72 57.28 139.74 57.28 164.34" />
        <line class="cls-2" x1="38.2" y1="153.32" x2="57.28" y2="164.34" />
        <polyline class="cls-2" points="76.12 290.77 76.12 266.53 94.36 277.06 94.36 301.3" />
        <line class="cls-2" x1="75.76" y1="290.56" x2="94.72" y2="301.51" />
        <polyline class="cls-2" points="113.56 312.39 113.56 288.15 131.92 298.75 131.92 322.99" />
        <line class="cls-2" x1="113.2" y1="312.18" x2="132.28" y2="323.2" />
        <polyline class="cls-2" points="76.12 262.21 76.12 237.97 94.36 248.5 94.36 272.74" />
        <line class="cls-2" x1="75.76" y1="262" x2="94.72" y2="272.95" />
        <polyline class="cls-2" points="113.56 283.83 113.56 259.59 131.92 270.19 131.92 294.43" />
        <line class="cls-2" x1="113.2" y1="283.62" x2="132.28" y2="294.64" />
        <polyline class="cls-2" points="76.12 233.77 76.12 209.41 94.36 219.94 94.36 244.3" />
        <line class="cls-2" x1="75.76" y1="233.56" x2="94.72" y2="244.51" />
        <polyline class="cls-2" points="113.56 255.39 113.56 231.03 131.92 241.63 131.92 265.99" />
        <line class="cls-2" x1="113.2" y1="255.18" x2="132.28" y2="266.2" />
        <polyline class="cls-2" points="76.12 175.21 76.12 150.97 94.36 161.5 94.36 185.74" />
        <line class="cls-2" x1="75.76" y1="175" x2="94.72" y2="185.95" />
        <polyline class="cls-2" points="113.2 196.62 113.2 172.02 132.28 183.04 132.28 207.64" />
        <line class="cls-2" x1="113.2" y1="196.62" x2="132.28" y2="207.64" />
        <polyline class="cls-2" points="58.96 286.63 51.52 282.33 51.52 297.45 58.96 301.75 58.96 286.63" />
        <polyline class="cls-2" points="81.52 299.65 73.96 295.29 73.96 310.41 81.52 314.77 81.52 299.65" />
        <polyline class="cls-2" points="96.52 308.31 88.96 303.95 88.96 319.07 96.52 323.43 96.52 308.31" />
        <polyline class="cls-2"
            points="118.96 321.27 111.52 316.97 111.52 332.09 118.96 336.39 118.96 321.27" />
        <polyline class="cls-2"
            points="246.52 421.79 238.96 417.43 238.96 432.55 246.52 436.91 246.52 421.79" />
        <polyline class="cls-2"
            points="231.52 413.13 223.96 408.77 223.96 423.89 231.52 428.25 231.52 413.13" />
        <polyline class="cls-2"
            points="246.52 447.47 238.96 443.11 238.96 458.23 246.52 462.59 246.52 447.47" />
        <polyline class="cls-2"
            points="231.52 438.81 223.96 434.45 223.96 449.57 231.52 453.93 231.52 438.81" />
        <polyline class="cls-2"
            points="246.52 472.79 238.96 468.43 238.96 483.55 246.52 487.91 246.52 472.79" />
        <polyline class="cls-2"
            points="231.52 464.13 223.96 459.77 223.96 474.89 231.52 479.25 231.52 464.13" />
        <polyline class="cls-2"
            points="283.96 494.41 276.52 490.11 276.52 505.23 283.96 509.53 283.96 494.41" />
        <polyline class="cls-2"
            points="268.96 485.75 261.52 481.45 261.52 496.57 268.96 500.87 268.96 485.75" />
        <polyline class="cls-2"
            points="208.96 451.11 201.52 446.81 201.52 461.93 208.96 466.23 208.96 451.11" />
        <polyline class="cls-2"
            points="283.96 469.09 276.52 464.79 276.52 479.91 283.96 484.21 283.96 469.09" />
        <polyline class="cls-2"
            points="268.96 460.43 261.52 456.13 261.52 471.25 268.96 475.55 268.96 460.43" />
        <polyline class="cls-2"
            points="283.96 443.41 276.52 439.11 276.52 454.23 283.96 458.53 283.96 443.41" />
        <polyline class="cls-2"
            points="268.96 434.75 261.52 430.45 261.52 445.57 268.96 449.87 268.96 434.75" />
        <polyline class="cls-2"
            points="283.96 414.85 276.52 410.55 276.52 425.67 283.96 429.97 283.96 414.85" />
        <polyline class="cls-2"
            points="268.96 406.19 261.52 401.89 261.52 418.01 268.96 422.31 268.96 409.19" />
        <polyline class="cls-2"
            points="283.96 384.21 276.52 379.91 276.52 395.03 283.96 399.33 283.96 384.21" />
        <polyline class="cls-2"
            points="268.96 375.55 261.52 371.25 261.52 386.37 268.96 390.67 268.96 375.55" />
        <polyline class="cls-2"
            points="283.96 327.21 276.52 322.91 276.52 338.03 283.96 342.33 283.96 327.21" />
        <polyline class="cls-2"
            points="268.96 318.55 261.52 314.25 261.52 329.37 268.96 333.67 268.96 318.55" />
        <polyline class="cls-2"
            points="283.96 298.77 276.52 294.47 276.52 309.59 283.96 313.89 283.96 298.77" />
        <polyline class="cls-2"
            points="268.96 290.11 261.52 285.81 261.52 300.93 268.96 305.23 268.96 290.11" />
        <polyline class="cls-2"
            points="283.96 271.77 276.52 267.47 276.52 282.59 283.96 286.89 283.96 271.77" />
        <polyline class="cls-2"
            points="268.96 263.11 261.52 258.81 261.52 273.93 268.96 278.23 268.96 263.11" />
        <polyline class="cls-2"
            points="246.52 393.23 238.96 388.87 238.96 403.99 246.52 408.35 246.52 393.23" />
        <polyline class="cls-2"
            points="231.52 384.57 223.96 380.21 223.96 395.33 231.52 399.69 231.52 384.57" />
        <polyline class="cls-2"
            points="231.52 353.93 223.96 349.57 223.96 364.69 231.52 369.05 231.52 353.93" />
        <polyline class="cls-2"
            points="231.52 296.93 223.96 292.57 223.96 307.69 231.52 312.05 231.52 296.93" />
        <polyline class="cls-2"
            points="231.52 268.49 223.96 264.13 223.96 279.25 231.52 283.61 231.52 268.49" />
        <polyline class="cls-2"
            points="246.52 250.15 238.96 245.79 238.96 260.91 246.52 265.27 246.52 250.15" />
        <polyline class="cls-2"
            points="231.52 241.49 223.96 237.13 223.96 252.25 231.52 256.61 231.52 241.49" />
        <polyline class="cls-2" points="38.56 183.53 38.56 158.09 56.92 168.69 56.92 194.13" />
        <line class="cls-2" x1="38.2" y1="183.32" x2="57.28" y2="194.34" />
        <polyline class="cls-2" points="76.12 205.21 76.12 179.77 94.36 190.3 94.36 215.74" />
        <line class="cls-2" x1="75.76" y1="205" x2="94.72" y2="215.95" />
        <polyline class="cls-2" points="113.56 226.83 113.56 201.39 131.92 211.99 131.92 237.43" />
        <line class="cls-2" x1="113.2" y1="226.62" x2="132.28" y2="237.64" />
        <polyline class="cls-2" points="191.8 265 191.8 239.56 210.04 250.09 210.04 275.53" />
        <line class="cls-2" x1="191.44" y1="264.79" x2="210.4" y2="275.74" />
        <polyline class="cls-2" points="191.8 350.56 191.8 326.32 210.04 336.85 210.04 361.09" />
        <line class="cls-2" x1="191.44" y1="350.35" x2="210.4" y2="361.3" />
        <polyline class="cls-2" points="191.8 322 191.8 297.76 210.04 308.29 210.04 332.53" />
        <line class="cls-2" x1="191.44" y1="321.79" x2="210.4" y2="332.74" />
        <polyline class="cls-2" points="191.8 293.56 191.8 269.2 210.04 279.73 210.04 304.09" />
        <line class="cls-2" x1="191.44" y1="293.35" x2="210.4" y2="304.3" />
        <polyline class="cls-2" points="191.8 235 191.8 210.76 210.04 221.29 210.04 245.53" />
        <line class="cls-2" x1="191.44" y1="234.79" x2="210.4" y2="245.74" />
        <polyline class="cls-2"
            points="223.96 321.13 231.52 325.49 231.52 340.61 223.96 336.25 223.96 321.13" />
        <polyline class="cls-2"
            points="261.52 342.81 268.96 347.11 268.96 362.23 261.52 357.93 261.52 342.81" />
        <polyline class="cls-2"
            points="276.52 351.47 283.96 355.77 283.96 370.89 276.52 366.59 276.52 351.47" />
        <polyline class="cls-2"
            points="208.96 371.55 201.52 367.25 201.52 382.37 208.96 386.67 208.96 371.55" />
        <polyline class="cls-2"
            points="193.96 362.89 186.52 358.59 186.52 373.71 193.96 378.01 193.96 362.89" />
        <polyline class="cls-2"
            points="171.52 349.93 163.96 345.57 163.96 360.69 171.52 365.05 171.52 349.93" />
        <polyline class="cls-2"
            points="208.96 425.79 201.52 421.49 201.52 436.61 208.96 440.91 208.96 425.79" />
        <polyline class="cls-2"
            points="208.96 400.11 201.52 395.81 201.52 410.93 208.96 415.23 208.96 400.11" />
        <polyline class="cls-2"
            points="38.83 81.13 38.83 80.72 39.76 78.38 33.04 74.02 26.32 70.62 27.16 73.98 27.16 104.71 38.41 111.2" />
        <polyline class="cls-2"
            points="208.2 178.93 208.2 178.51 207.28 175.1 214 178.5 220.72 182.86 219.88 185.25 219.88 215.97" />
        <polyline class="cls-2"
            points="219.88 209.04 293.92 251.79 293.92 519.77 305.56 526.44 305.56 597.29" />
        <line class="cls-2" x1="209.42" y1="179.7" x2="219.76" y2="216.26" />
        <polyline class="cls-2" points="37.68 80.54 27.04 105 23.32 102.86 23.32 435.49" />
        <polygon class="cls-2" points="99.64 149.07 92.04 144.69 92.04 131.13 99.64 135.51 99.64 149.07" />
        <polygon class="cls-2"
            points="160.19 184.03 152.6 179.65 152.6 166.09 160.19 170.47 160.19 184.03" />
        <polygon class="cls-2"
            points="169.19 189.23 161.6 184.85 161.6 171.29 169.19 175.67 169.19 189.23" />
        <polygon class="cls-2"
            points="114.64 164.45 106.31 159.65 106.31 138.97 114.64 143.78 114.64 164.45" />
        <polygon class="cls-2"
            points="156.52 221.75 148.96 217.39 148.96 197.83 156.52 202.19 156.52 221.75" />
        <polygon class="cls-2"
            points="171.52 230.41 163.96 226.05 163.96 206.49 171.52 210.85 171.52 230.41" />
        <polygon class="cls-2"
            points="156.52 248.93 148.96 244.56 148.96 225 156.52 229.37 156.52 248.93" />
        <polygon class="cls-2"
            points="171.52 257.59 163.96 253.22 163.96 233.66 171.52 238.03 171.52 257.59" />
        <polygon class="cls-2"
            points="171.52 285.41 163.96 281.05 163.96 261.49 171.52 265.85 171.52 285.41" />
        <polygon class="cls-2"
            points="171.52 312.59 163.96 308.22 163.96 288.66 171.52 293.03 171.52 312.59" />
        <polygon class="cls-2"
            points="171.52 338.73 163.96 334.37 163.96 314.81 171.52 319.17 171.52 338.73" />
        <line class="cls-2" x1="209.42" y1="179.7" x2="37.68" y2="80.54" />
        <line class="cls-2" x1="331.36" y1="144.67" x2="405.36" y2="187.46" />
        <line class="cls-2" x1="396.6" y1="186.98" x2="293.31" y2="246.09" />
        <line class="cls-2" x1="327.42" y1="146.55" x2="396.6" y2="186.98" />
        <line class="cls-2" x1="224.7" y1="205.55" x2="293.31" y2="246.09" />
        <line class="cls-2" x1="327.42" y1="156.61" x2="386.95" y2="191.9" />
        <line class="cls-2" x1="137.52" y1="5.96" x2="201.92" y2="43.2" />
        <line class="cls-2" x1="224.7" y1="205.55" x2="220.18" y2="208.19" />
    </g>
    <g id="Build_4">
        <g id="Build4">
            <g id="Build1">
                <polyline class="cls-2"
                    points="333.92 159.66 326.48 163.95 326.48 177.51 333.92 173.22 333.92 159.66" />
                <polyline class="cls-2"
                    points="348.92 151 341.48 155.29 341.48 168.85 348.92 164.56 348.92 151" />
                <polyline class="cls-2"
                    points="371.48 137.97 363.92 142.34 363.92 155.9 371.48 151.53 371.48 137.97" />
                <polyline class="cls-2"
                    points="386.48 129.31 378.92 133.68 378.92 147.24 386.48 142.87 386.48 129.31" />
                <line class="cls-2" x1="322.99" y1="111.81" x2="405.05" y2="64.06" />
                <line class="cls-2" x1="210.05" y1="155.5" x2="210.05" y2="207.72" />
                <polyline class="cls-2"
                    points="375.29 100.68 367.73 105.04 367.73 118.48 375.29 114.12 375.29 100.68" />
                <polyline class="cls-2"
                    points="393.65 90.08 386.21 94.37 386.21 107.81 393.65 103.52 393.65 90.08" />
                <polyline class="cls-2"
                    points="337.73 122.36 330.29 126.66 330.29 140.1 337.73 135.8 337.73 122.36" />
                <polyline class="cls-2"
                    points="356.09 111.76 348.65 116.06 348.65 129.5 356.09 125.2 356.09 111.76" />
                <polyline class="cls-2"
                    points="384.41 95.41 376.97 99.71 376.97 113.15 384.41 108.85 384.41 95.41" />
                <polyline class="cls-2"
                    points="346.97 117.03 339.41 121.39 339.41 134.83 346.97 130.47 346.97 117.03" />
                <polyline class="cls-2" points="117.99 597.81 405.05 429.22 405.05 64.06" />
                <line class="cls-2" x1="210.05" y1="207.12" x2="134.33" y2="250.84" />
                <line class="cls-2" x1="314.99" y1="12.2" x2="108.99" y2="126.2" />
                <line class="cls-2" x1="277.5" y1="66.5" x2="164.5" y2="130.5" />
                <polyline class="cls-2" points="210.32 155.87 322.61 91.04 322.61 111.65" />
                <line class="cls-2" x1="277.21" y1="67.12" x2="322.73" y2="91.09" />
                <line class="cls-2" x1="164.73" y1="130.79" x2="210.25" y2="154.76" />
                <line class="cls-2" x1="163.99" y1="158.5" x2="164.14" y2="130.7" />
                <polyline class="cls-2"
                    points="333.92 188.27 326.48 192.57 326.48 206.13 333.92 201.83 333.92 188.27" />
                <polyline class="cls-2"
                    points="348.92 179.61 341.48 183.91 341.48 197.47 348.92 193.17 348.92 179.61" />
                <polyline class="cls-2"
                    points="371.48 166.59 363.92 170.95 363.92 184.51 371.48 180.15 371.48 166.59" />
                <polyline class="cls-2"
                    points="386.48 157.93 378.92 162.29 378.92 175.85 386.48 171.49 386.48 157.93" />
                <polyline class="cls-2"
                    points="333.92 216.89 326.48 221.18 326.48 234.74 333.92 230.45 333.92 216.89" />
                <polyline class="cls-2"
                    points="348.92 208.23 341.48 212.52 341.48 226.08 348.92 221.79 348.92 208.23" />
                <polyline class="cls-2"
                    points="371.48 195.2 363.92 199.57 363.92 213.13 371.48 208.76 371.48 195.2" />
                <polyline class="cls-2"
                    points="386.48 186.54 378.92 190.91 378.92 204.47 386.48 200.1 386.48 186.54" />
                <polyline class="cls-2"
                    points="333.92 245.5 326.48 249.8 326.48 263.36 333.92 259.06 333.92 245.5" />
                <polyline class="cls-2"
                    points="348.92 236.84 341.48 241.14 341.48 254.7 348.92 250.4 348.92 236.84" />
                <polyline class="cls-2"
                    points="371.48 223.82 363.92 228.18 363.92 241.74 371.48 237.38 371.48 223.82" />
                <polyline class="cls-2"
                    points="386.48 215.16 378.92 219.52 378.92 233.08 386.48 228.72 386.48 215.16" />
                <polyline class="cls-2"
                    points="333.92 274.12 326.48 278.42 326.48 291.98 333.92 287.68 333.92 274.12" />
                <polyline class="cls-2"
                    points="348.92 265.46 341.48 269.76 341.48 283.32 348.92 279.02 348.92 265.46" />
                <polyline class="cls-2"
                    points="371.48 252.43 363.92 256.8 363.92 270.36 371.48 265.99 371.48 252.43" />
                <polyline class="cls-2"
                    points="386.48 243.77 378.92 248.14 378.92 261.7 386.48 257.33 386.48 243.77" />
                <polyline class="cls-2"
                    points="333.92 302.74 326.48 307.03 326.48 320.59 333.92 316.3 333.92 302.74" />
                <polyline class="cls-2"
                    points="348.92 294.08 341.48 298.37 341.48 311.93 348.92 307.64 348.92 294.08" />
                <polyline class="cls-2"
                    points="371.48 281.05 363.92 285.41 363.92 298.97 371.48 294.61 371.48 281.05" />
                <polyline class="cls-2"
                    points="386.48 272.39 378.92 276.75 378.92 290.31 386.48 285.95 386.48 272.39" />
                <polyline class="cls-2"
                    points="333.92 331.35 326.48 335.65 326.48 349.21 333.92 344.91 333.92 331.35" />
                <polyline class="cls-2"
                    points="348.92 322.69 341.48 326.99 341.48 340.55 348.92 336.25 348.92 322.69" />
                <polyline class="cls-2"
                    points="371.48 309.67 363.92 314.03 363.92 327.59 371.48 323.23 371.48 309.67" />
                <polyline class="cls-2"
                    points="386.48 301.01 378.92 305.37 378.92 318.93 386.48 314.57 386.48 301.01" />
                <polyline class="cls-2"
                    points="333.92 359.97 326.48 364.26 326.48 377.82 333.92 373.53 333.92 359.97" />
                <polyline class="cls-2"
                    points="348.92 351.31 341.48 355.6 341.48 369.16 348.92 364.87 348.92 351.31" />
                <polyline class="cls-2"
                    points="371.48 338.28 363.92 342.65 363.92 356.21 371.48 351.84 371.48 338.28" />
                <polyline class="cls-2"
                    points="386.48 329.62 378.92 333.99 378.92 347.55 386.48 343.18 386.48 329.62" />
                <polyline class="cls-2"
                    points="333.92 388.58 326.48 392.88 326.48 406.44 333.92 402.14 333.92 388.58" />
                <polyline class="cls-2"
                    points="348.92 379.92 341.48 384.22 341.48 397.78 348.92 393.48 348.92 379.92" />
                <polyline class="cls-2"
                    points="371.48 366.9 363.92 371.26 363.92 384.82 371.48 380.46 371.48 366.9" />
                <polyline class="cls-2"
                    points="386.48 358.24 378.92 362.6 378.92 376.16 386.48 371.8 386.48 358.24" />
                <line class="cls-2" x1="203.5" y1="204.5" x2="208.89" y2="207.58" />
                <line class="cls-2" x1="108.5" y1="137.5" x2="108.5" y2="126.5" />
                <line class="cls-2" x1="112.5" y1="159.5" x2="112.64" y2="139.7" />
            </g>
            <g id="Build_3-2">
                <polyline class="cls-2" points="35.44 235.23 35.44 210.63 54.52 221.64 54.52 246.24" />
                <line class="cls-2" x1="35.44" y1="235.23" x2="54.52" y2="246.24" />
                <polyline class="cls-2" points="35.44 265.23 35.44 240.63 54.52 251.64 54.52 276.24" />
                <line class="cls-2" x1="35.44" y1="265.23" x2="54.52" y2="276.24" />
                <polyline class="cls-2" points="35.44 293.79 35.44 269.07 54.52 280.08 54.52 304.8" />
                <line class="cls-2" x1="35.44" y1="293.79" x2="54.52" y2="304.8" />
                <polyline class="cls-2" points="35.44 322.23 35.44 297.63 54.52 308.64 54.52 333.24" />
                <line class="cls-2" x1="35.44" y1="322.23" x2="54.52" y2="333.24" />
                <polyline class="cls-2" points="35.44 350.79 35.44 326.07 54.52 337.08 54.52 361.8" />
                <line class="cls-2" x1="35.44" y1="350.79" x2="54.52" y2="361.8" />
                <polyline class="cls-2" points="35.44 379.43 35.44 354.71 54.52 365.72 54.52 390.44" />
                <line class="cls-2" x1="35.44" y1="379.43" x2="54.52" y2="390.44" />
                <polyline class="cls-2" points="35.44 407.99 35.44 383.27 54.52 394.28 54.52 419" />
                <line class="cls-2" x1="35.44" y1="407.99" x2="54.52" y2="419" />
                <polyline class="cls-2" points="35.44 436.43 35.44 411.83 54.52 422.84 54.52 447.44" />
                <line class="cls-2" x1="35.44" y1="436.43" x2="54.52" y2="447.44" />
                <polyline class="cls-2" points="101.68 273.47 101.68 248.87 120.76 259.89 120.76 284.49" />
                <line class="cls-2" x1="101.68" y1="273.47" x2="120.76" y2="284.49" />
                <polyline class="cls-2" points="101.68 303.47 101.68 278.87 120.76 289.89 120.76 314.49" />
                <line class="cls-2" x1="101.68" y1="303.47" x2="120.76" y2="314.49" />
                <polyline class="cls-2" points="101.68 332.03 101.68 307.31 120.76 318.33 120.76 343.05" />
                <line class="cls-2" x1="101.68" y1="332.03" x2="120.76" y2="343.05" />
                <polyline class="cls-2" points="101.68 360.47 101.68 335.87 120.76 346.89 120.76 371.49" />
                <line class="cls-2" x1="101.68" y1="360.47" x2="120.76" y2="371.49" />
                <polyline class="cls-2" points="101.68 389.03 101.68 364.31 120.76 375.33 120.76 400.05" />
                <line class="cls-2" x1="101.68" y1="389.03" x2="120.76" y2="400.05" />
                <polyline class="cls-2" points="101.68 417.67 101.68 392.95 120.76 403.97 120.76 428.69" />
                <line class="cls-2" x1="101.68" y1="417.67" x2="120.76" y2="428.69" />
                <polyline class="cls-2" points="101.68 446.23 101.68 421.51 120.76 432.53 120.76 457.25" />
                <line class="cls-2" x1="101.68" y1="446.23" x2="120.76" y2="457.25" />
                <polyline class="cls-2" points="101.68 474.67 101.68 450.07 120.76 461.09 120.76 485.69" />
                <line class="cls-2" x1="101.68" y1="474.67" x2="120.76" y2="485.69" />
                <polyline class="cls-2"
                    points="122.44 494.66 115 490.36 115 503.92 122.44 508.22 122.44 494.66" />
                <polyline class="cls-2" points="107.44 486 100 481.7 100 495.26 107.44 499.56 107.44 486" />
                <polyline class="cls-2"
                    points="89.32 475.54 81.76 471.17 81.76 484.73 89.32 489.1 89.32 475.54" />
                <polyline class="cls-2"
                    points="74.44 466.95 67 462.65 67 476.21 74.44 480.51 74.44 466.95" />
                <polyline class="cls-2"
                    points="56.2 456.41 48.76 452.12 48.76 465.68 56.2 469.97 56.2 456.41" />
                <polyline class="cls-2"
                    points="41.2 447.75 33.76 443.46 33.76 457.02 41.2 461.31 41.2 447.75" />
                <polyline class="cls-2"
                    points="89.32 446.78 81.76 442.41 81.76 455.85 89.32 460.22 89.32 446.78" />
                <polyline class="cls-2"
                    points="74.44 438.19 67 433.89 67 447.33 74.44 451.63 74.44 438.19" />
                <polyline class="cls-2"
                    points="89.32 417.22 81.76 412.85 81.76 426.41 89.32 430.78 89.32 417.22" />
                <polyline class="cls-2"
                    points="74.44 408.63 67 404.33 67 417.89 74.44 422.19 74.44 408.63" />
                <polyline class="cls-2"
                    points="89.32 387.66 81.76 383.29 81.76 396.85 89.32 401.22 89.32 387.66" />
                <polyline class="cls-2"
                    points="74.44 379.07 67 374.77 67 388.33 74.44 392.63 74.44 379.07" />
                <polyline class="cls-2"
                    points="89.32 361.14 81.76 356.77 81.76 370.21 89.32 374.58 89.32 361.14" />
                <polyline class="cls-2"
                    points="74.44 352.55 67 348.25 67 361.69 74.44 365.99 74.44 352.55" />
                <polyline class="cls-2"
                    points="89.32 332.58 81.76 328.21 81.76 341.65 89.32 346.02 89.32 332.58" />
                <polyline class="cls-2"
                    points="74.44 323.99 67 319.69 67 333.13 74.44 337.43 74.44 323.99" />
                <polyline class="cls-2"
                    points="89.32 304.02 81.76 299.65 81.76 313.21 89.32 317.58 89.32 304.02" />
                <polyline class="cls-2"
                    points="74.44 295.43 67 291.13 67 304.69 74.44 308.99 74.44 295.43" />
                <polyline class="cls-2"
                    points="89.32 275.58 81.76 271.21 81.76 284.65 89.32 289.02 89.32 275.58" />
                <polyline class="cls-2"
                    points="74.44 266.99 67 262.69 67 276.13 74.44 280.43 74.44 266.99" />
                <polyline class="cls-2"
                    points="89.32 245.58 81.76 241.21 81.76 254.65 89.32 259.02 89.32 245.58" />
                <polyline class="cls-2"
                    points="74.44 236.99 67 232.69 67 246.13 74.44 250.43 74.44 236.99" />
                <polyline class="cls-2"
                    points="89.32 505.54 81.76 501.17 81.76 514.73 89.32 519.1 89.32 505.54" />
                <line class="cls-2" x1="134.08" y1="550.58" x2="134.08" y2="250.18" />
                <line class="cls-2" x1="6.54" y1="533.62" x2="118.02" y2="597.99" />
                <polyline class="cls-2" points="134.08 250.18 22.6 185.82 22.6 453.82" />
                <line class="cls-2" x1="61.96" y1="476.54" x2="22.6" y2="453.82" />
                <line class="cls-2" x1="134.08" y1="550.58" x2="61.99" y2="508.81" />
                <polyline class="cls-2" points="118.08 558.44 45.96 516.8 45.96 484.4 6.6 462.67" />
                <line class="cls-2" x1="45.96" y1="484.4" x2="61.96" y2="476.54" />
                <line class="cls-2" x1="118.08" y1="559.02" x2="134.08" y2="551.17" />
                <line class="cls-2" x1="118" y1="597.97" x2="118.08" y2="558.36" />
                <polyline class="cls-2" points="22.6 185.84 22.6 453.82 6.24 462.93 6.24 533.78" />
                <line class="cls-2" x1="135.06" y1="244.08" x2="31.78" y2="183.92" />
                <line class="cls-2" x1="136.06" y1="244.08" x2="203.5" y2="204.5" />
                <line class="cls-2" x1="33.06" y1="184.08" x2="112.5" y2="139.5" />
                <line class="cls-2" x1="50.5" y1="194.5" x2="112.5" y2="159.5" />
                <line class="cls-2" x1="404.96" y1="64" x2="314.5" y2="12.5" />
                <line class="cls-2" x1="197.18" y1="208.76" x2="112.71" y2="160.26" />
                <line class="cls-2" x1="163.5" y1="158.5" x2="108.5" y2="126.5" />
                <line class="cls-2" x1="112.5" y1="139.5" x2="108.5" y2="137.5" />
                <line class="cls-2" x1="22.5" y1="185.5" x2="107.56" y2="137.82" />
            </g>
            <line class="cls-2" x1="61.99" y1="508.81" x2="61.99" y2="476.81" />
        </g>
        <polyline class="cls-2" points="169.02 191.87 169.02 171.11 175.74 174.99 175.74 195.75" />
        <polyline class="cls-2" points="145.98 178.56 145.98 155.88 164.1 166.34 164.1 189.02" />
        <polyline class="cls-2" points="117.22 162.54 117.22 138.86 135.22 149.25 135.22 171.93" />
    </g>
</svg>

      </div>

      <div class="table-wrapper" :class="tableClass">
        <div class="table-row table-row--first table-row--first-fixed">
            <div class="table-row-item">UNIT</div>
            <div class="table-row-item">BED</div>
            <div class="table-row-item">BATH</div>
            <div class="table-row-item">TOTAL SQFT</div>
            <div class="table-row-item">INT.SQFT</div>
            <div class="table-row-item">EXT.SQFT</div>
            <div class="table-row-item" v-show="type === 'available'">CC</div>
            <div class="table-row-item" v-show="type === 'available'">RET</div>
            <div class="table-row-item" v-show="type === 'available'">PRICE</div>
            <div class="table-row-item">FLOORPLAN</div>
        </div>
        <div class="table-rows">
          <div
            class="table-row-unit table-row"
            :class="{ isActive: activeUnit === unit.id }"
            v-for="unit in units"
            :key="unit.id"
            @click="onAvailabilityItemClick(unit)"
          >
            <div class="table-row-item">{{ unit.name }}</div>
            <div class="table-row-item">
              {{ formatNum(unit.bedroom) }}
            </div>
            <div class="table-row-item">
              {{ formatNum(unit.bathroom) }}
            </div>
            <div class="table-row-item">
              {{ unit.total_sqft }}
            </div>
            <div class="table-row-item">
              {{ unit.int_sqft }}
            </div>
            <div class="table-row-item">
              {{ unit.ext_sqft }}
            </div>
            <div class="table-row-item" v-show="type === 'available'">
              {{ unit.cc }}
            </div>
            <div class="table-row-item" v-show="type === 'available'">
              {{ unit.tax }}
            </div>
            <div class="table-row-item" v-show="type === 'available'">
              {{ unit.price }}
            </div>
            <div class="table-row-item">
                <a href="#" class="unit-view" @click.prevent="selectUnit(unit)">View</a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="preview-unit animated faster">
      <div class="close" @click.prevent="closeUnit"></div>

      <div class="preview-unit-meta animated faster fadeIn">
        <div class="preview-unit-meta--title">
          <div class="preview-unit-meta--name">{{ previewUnit.name }}</div>
        </div>

        <div>
          <div class="preview-unit-meta--subtitle">
            {{ previewUnit.bedroom }} Bed, {{ previewUnit.bathroom }} Bath
          </div>

          <div class="preview-unit-meta--subtitle">
            {{ previewUnit.total_sqft }} Total SqFt
          </div>

          <div class="preview-unit-meta--subtitle">
            {{ previewUnit.int_sqft }} Int. SqFt
          </div>

          <div class="preview-unit-meta--subtitle">
            {{ previewUnit.ext_sqft }} Ext. SqFt
          </div>
        </div>
      </div>

      <div
        class="units-slider-wrapper animated faster fadeIn delay-03s"
        v-if="previewUnit"
      >
        <div class="units-slider-item">
          <div
            class="units-slider-image units-slider-image--florplan"
            style="background-image: url('')"
            :data-responsimgtablet="`/images/Floorplans/${previewUnit.name}_tablet.png`"
            :data-responsimgtv="`/images/Floorplans/${previewUnit.name}.png`"
          ></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      unitsData: [],
      units: [],
      previewUnit: false,

      tableClass: "",

      previewUnitSlide: 1,

      additionals: [],
      type: "available",
      togglePlan: "PH2",

      activeUnit: null,
    };
  },

  mounted() {
    this.fetchUnits();

    this.tableClass = this.type = this.$route.params.type;

    window.Event.listen("preview-unit::hide", () => {
      setTimeout(() => (this.previewUnit = false), 75);
    });

    this.availabilityItemReset();
  },

  beforeDestroy() {
    this.availabilityItemReset();
  },

  watch: {
    "$route.params.type": function (type) {
      this.type = type;
      this.getData();
      this.tableClass = type;

      this.availabilityItemReset();
    },

    unitsData() {
      this.getData();
    },

    previewUnit(value) {
      $("body").toggleClass("preview-unit-open");

      if (!value) {
        this.togglePlan = "PH2";
        this.previewUnitSlide = 1;
      }
    },
  },

  methods: {
    getData() {
      const units = this.unitsData;
      const type = this.$route.params.type;

      if (type === "available") {
        return (this.units = units.filter((unit) => {
          return unit.display;
        }));
      }

      if (type === "1-bedroom") {
        return (this.units = units.filter((unit) => {
          return parseInt(unit.bedroom) == 1;
        }));
      }

      if (type === "2-bedroom") {
        return (this.units = units.filter((unit) => {
          return parseInt(unit.bedroom) == 2;
        }));
      }

      if (type === "3-plus-bedroom") {
        return (this.units = units.filter((unit) => {
          return parseInt(unit.bedroom) >= 3;
        }));
      }
    },

    async fetchUnits(type) {
      // const url = 'http://127.0.0.1:8000/api/units';
      const url = 'https://archivelofts.com/api/units';

      const { data } = await axios.get(url);

      this.unitsData = data.data;
    },

    selectUnit(unit) {
      this.previewUnit = unit;

      setTimeout(() => {
        $(".units-slider-image").responsImg(window.imgOptions);

        setTimeout(
          () => $(".units-slider-image").responsImg(window.imgOptions),
          600
        );
      }, 55);
    },

    closeUnit() {
      this.previewUnit = false;
    },

    formatNumber(n, c, d, t) {
      if (this.isNull(n)) return "";

      var c = isNaN((c = Math.abs(c))) ? 2 : c,
        d = d == undefined ? "." : d,
        t = t == undefined ? "," : t,
        s = n < 0 ? "-" : "",
        i = String(parseInt((n = Math.abs(Number(n) || 0).toFixed(c)))),
        j = (j = i.length) > 3 ? j % 3 : 0;

      return (
        s +
        (j ? i.substr(0, j) + t : "") +
        i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + t) +
        (c
          ? d +
            Math.abs(n - i)
              .toFixed(c)
              .slice(2)
          : "")
      );
    },

    formatNum(num) {
      let number = String(num);

      return number == "2.5" ? "2.5" : number.replace(".0", "");
    },

    toM2(n) {
      if (this.isNull(n)) return "";

      return (parseFloat(n) * 0.09290304).toFixed(1);
    },

    calcTotalSqft(unit) {
      if (this.isNull(unit.exterior_area)) return parseFloat(unit.area);

      return parseFloat(unit.area) + parseFloat(unit.exterior_area);
    },

    calcPrice(n) {
      const m = n >= 1000000;

      return `${this.formatNumber(n).substring(0, m ? 5 : 3)}${m ? "M" : "K"}`;
    },

    print(s) {
      if (s === "0") return "";

      return s ? s : "";
    },

    isNull(n) {
      return !n || isNaN(n) || n == 0;
    },

    onAvailabilityItemClick(unit) {
      const apartmentData = unit.data;
      const apartmentName = apartmentData.substring(apartmentData.indexOf("#") + 1, apartmentData.indexOf(","));
      const buildingVersion = apartmentData.substring(apartmentData.lastIndexOf("#") + 1).trim();

      console.log(buildingVersion, apartmentName);
      
      $('#Build_1').css('display', 'none');
      $('#Build_2').css('display', 'none');
      $('#Build_3').css('display', 'none');
      $('#Build_4').css('display', 'none');
      $('#' + buildingVersion).css('display', 'block');
      $('.cls-1').removeClass('isActive');
      $('#' + apartmentName).addClass('isActive');
      // $('[data-availability]').removeClass('isActive');
      // $(this).addClass('isActive')

      console.log('#' + apartmentName);

      this.activeUnit = unit.id;
    },

    availabilityItemReset() {
      $('.cls-1').removeClass('isActive')
      $('#Build_1').css('display', 'none');
      $('#Build_2').css('display', 'block');
      $('#Build_3').css('display', 'none');
      $('#Build_4').css('display', 'none');
      this.activeUnit = null;
      // $('[data-availability]').removeClass('isActive');
    }
  },
};
</script>

<style scoped>
.close {
  position: absolute;
  height: 1.5rem; /* this can be anything */
  width: 1.5rem; /* ...but maintain 1:1 aspect ratio */
  display: flex;
  flex-direction: column;
  justify-content: center;
  top: 1rem;
  right: 1rem;
  z-index: 999;
}

@media screen and (min-width: 1400px) {
  .close {
    display: none;
  }
}

.close::before,
.close::after {
  position: absolute;
  content: "";
  width: 100%;
  height: 2px; /* cross thickness */
  background-color: #7C532A;
}

.close::before {
  transform: rotate(45deg);
}

.close::after {
  transform: rotate(-45deg);
}

.table-row {
  border-bottom: 1px solid #fff;
}

.table-row-item {
  color: #fff;
}

.table-row-unit .table-row-item {
  font-weight: 500;
}

.preview-unit-meta--title {
  color: #7C532A;
  margin-bottom: 1rem;
  font-family: 'NoeDisplay-Medium';
}

@media screen and (min-width: 1025px) {
  .preview-unit-meta--title {
    margin-bottom: 0;
  }
}

.preview-unit-meta--subtitle {
  color: #7C532A;
  margin-bottom: 0.2rem;
}

.table-alert {
  background-color: #e9f6f8;
  color: #fff;
}

.units-wrapper {
  height: 100vh;
  overflow: hidden;
}

.units-building {
  justify-content: center;
  width: 36%;
  height: 100%;
  float: left;
  display: flex;
}

@media screen and (min-width: 1400px) {
    .available .table-row-item:nth-child(10),
    .available .table-header-item:nth-child(10) {
        display: none;
    }
}

/* .available .units-building {
  width: 42%;
}

.available .table-header-item:nth-child(7),
.available .table-row-item:nth-child(7) {
  justify-content: end;
} */

/* .page:not(.available) .table-header-item,
.page:not(.available) .table-row-item {
  width: auto;
  flex: 1;
} */

.page .table-header-item,
.page .table-row-item {
  width: auto;
  flex: 1;
}

.units-building svg {
  max-width: 74%;
}

.unit-view {
    color: #fff;
    display: inline-block;
    text-decoration: none;
    border: 1px solid #fff;
    border-radius: 4px;
    padding: 0.25rem 1rem;
}

.cls-1 {
  opacity: 0;
}

.cls-1.isActive {
  fill: #c6b483;
  opacity: 1;
}

.cls-2 {
  fill: none;
  stroke: #fff;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: .5px;
}
</style>
