<template>
    <div class="page">
        <div class="owl-carousel owl-theme">
            <div class="item"
                 :class="`item-${index + 1}`"
                 style="background-image: url('');"
                 v-for="(image, index) in imageListNames"
                 :key="index"
                 :data-responsimgtablet="`/images/Building/${image}_tablet.${image.includes('List') ? 'png' : 'jpg'}`"
                 :data-responsimgtv="`/images/Building/${image}.${image.includes('List') ? 'png' : 'jpg'}`"
            ></div>
        </div>

        <div class="thumbnails-slider hidden-tv">
            <div
                    v-for="(image, index) in imageListNames"
                    :key="index"
                    class="thumbnails-slider-item"
                    :style="`background-image: url('/images/Building/${image}_tablet.${image.includes('List') ? 'png' : 'jpg'}')`"
                    @click.prevent="goToSlide(index, $event)"
            >
                <div class="thumbnails-slider-item--overlay" :class="index === 0 ? 'active' : ''">
                    <div class="thumbnails-slider-item--overlay-content animated faster">
                        <div class="thumbnails-slider-item--overlay-title" v-text="imageListLabels[index]"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import slidersMixins from "../mixins/sliders";
export default {
  mixins: [slidersMixins],
    data() {
        return {
            imageListNames: [
                "Birds-Eye-View",
                "Entry",
                "Lobby",
                "Lobby-2",
                "Aire-Spa",
                "Roof-Terrace",
            ],

            imageListLabels: [
                "Crown",
                "Entry",
                "Lobby",
                "Lobby Lounge",
                "Aire Spa",
                "Roof Terrace",
            ]
        }
    },
}
</script>
