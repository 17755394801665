export default {
  props: {
    onSlideChange: {
      type: Function,
      default: () => {},
    },
  },

  mounted() {
    $(".owl-carousel").owlCarousel(window.owlOptions);

    setTimeout(() => $(".item").responsImg(window.imgOptions), 25);

    if (this.imageListLabels.length) {
      this.onSlideChange(this.imageListLabels[0].toLowerCase());
    }
  },

  beforeDestroy() {
    this.onSlideChange(null);
  },

  methods: {
    goToSlide(index, event) {
      $(".owl-carousel").trigger("to.owl.carousel", [index, 20]);
      $(".thumbnails-slider-item--overlay").removeClass("active");
      $(event.target)
        .closest(".thumbnails-slider-item--overlay")
        .addClass("active");

        if (this.imageListLabels.length) {
          this.onSlideChange(this.imageListLabels[index].toLowerCase());
        }
    },
  },
};
