<template>
  <div class="menu-image"></div>
</template>

<script>
export default {
  mounted() {
    // setTimeout(() => $(".menu-image").responsImg(window.imgOptions), 25);
  },
};
</script>

<style scoped>
.menu-image {
  width: 50vw;
  height: 100vh;
  position: fixed;
  bottom: -100vh;
  left: 0;
  -webkit-transition: all 300ms;
  transition-timing-function: ease-out;
  transition: all 300ms;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;

  background-color: #fff;
  background-image: url(../assets/images/Hero_tablet.jpg);
  background-size: cover;
  background-repeat: no-repeat;

  @media screen and (min-width: 1400px) {
    background-image: url(../assets/images/Hero.jpg);
  }
}
</style>
