<template>
  <div :class="[menuOpened ? 'sidebar-open' : '', pageName, currentSlide]">
    <Sidebar :onLinkClick="toggleSidebar" />
    <MenuImage />

    <div class="content" @click="hideSidebar">
      <div v-if="!isLanding">
        <a
          class="sidebar-btn"
          href="#"
          @click.prevent="toggleSidebar"
          v-if="!menuOpened"
        >
          <span></span>
          <span></span>
        </a>

        <!-- <router-link to="/" class="landing-btn">
          <img :src="landingButtonImage" alt="Home" />
        </router-link> -->
      </div>

      <transition name="fade" mode="out-in">
        <router-view :on-slide-change="slideChanged"></router-view>
      </transition>
    </div>

    <div class="portrait-alert">
      <p class="portrait-alert-message">Rotate your device!</p>
    </div>
  </div>
</template>

<script>
import Sidebar from "./components/Sidebar";
import MenuImage from "./components/MenuImage";

export default {
  components: { Sidebar, MenuImage },

  data() {
    return {
      menuOpened: true,
      currentSlide: null,
    };
  },

  methods: {
    toggleSidebar() {
      if ($("body").hasClass("preview-unit-open")) {
        return window.Event.fire("preview-unit::hide");
      }

      setTimeout(() => (this.menuOpened = !this.menuOpened), 0);
    },

    hideSidebar() {
      if (this.menuOpened) return (this.menuOpened = false);
    },

    slideChanged(value) {
      this.currentSlide = value;
    },
  },

  computed: {
    type() {
      const route = this.$route.name;

      if (route === "landing") {
        return "";
      }

      return this.$route.name.includes("keller") ? "keller" : "charles";
    },

    pageName() {
      const route = this.$route.name;

      return route.includes(".") ? route.split(".")[1] : route;
    },

    isLanding() {
      return this.pageName === "landing";
    },

    landingButtonImage() {
      const slide = `${this.type}-${this.currentSlide}`;

      if (slide === "keller-list") {
        return "/images/home_green.svg";
      }

      if (slide === "charles-list") {
        return "/images/home_red.svg";
      }

      switch (`${this.type}-${this.pageName}`) {
        case "keller-map":
        case "keller-units":
          return "/images/home_green.svg";
        case "charles-map":
        case "charles-units":
          return "/images/home_red.svg";
        default:
          return "/images/home_white.svg";
      }
    },
  },

  watch: {
    $route(to, from) {
      // setTimeout(() => this.toggleSidebar(), 300);
    },

    menuOpened(value) {
      window.Event.fire("sidebar-toggle", value);
    },
  },
};
</script>

<style scoped>
@media screen and (min-width: 1400px) {
  .landing-btn,
  .sidebar-btn {
    display: none;
  }
}
</style>
